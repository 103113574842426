import { useCallback, useEffect, useState } from 'react'
import { CurrencyData } from '@/utils/apis/types'
import Cookies from 'js-cookie'
import { useQuery } from 'react-query'
import { fetchCountryInfo, fetchGeoInfo } from '@/utils/apis/settings'

export const useCountry = () => {
  const { data: geoData } = useQuery(['fetchGeoInfo'], fetchGeoInfo, {
    refetchOnWindowFocus: false,
  })
  const [country, setCountry] = useState<CurrencyData | null>()

  const fetchData = useCallback(async (): Promise<void> => {
    try {
      if (!geoData) return
      //'1.54.3.194' vietnam
      //'102.128.192.194' nigeria
      //'23.81.69.194' us
      const geo: { lang: string; info: { ip: string } } = geoData
      const localIp = geo.info.ip === '::1' ? '23.81.69.194' : geo.info.ip
      const data = await fetchCountryInfo({ ip: localIp })
      const countryData = { lang: geo.lang, ...data }
      Cookies.set('country', JSON.stringify(countryData))
      setCountry(countryData)
    } catch (err) {
      setCountry({
        lang: 'en',
        country: {
          isoCode: 'US',
          names: { en: 'United States' },
        },
      })
    }
  }, [geoData])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return [country] as const
}
