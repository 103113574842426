import Cookies from 'js-cookie'

import { TokenMarketInfoModel } from '@/root/src/model/token-market-info'

import {
  getFromApi,
  postToApi,
} from './'
import { CurrencyData } from './types'

export const registerWithCaptcha = async (
  captcha: string,
  email: string,
  referral_code?: string | null,
) => {
  const country: CurrencyData = JSON.parse(decodeURIComponent(Cookies.get('country') || '{}'))
  const locale = (Cookies.get('locale') || country.lang || 'en').toLowerCase()
  return await fetch('/api/auth/login', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-signup-captcha': captcha,
      'x-propeasy-locale': locale,
    },
    body: JSON.stringify({
      email: email,
      referral_code: referral_code,
    }),
  })
}

export const login = async (payload: { token: string }) => {
  return await postToApi(`/propeasy/user/login/${payload.token}`, {})
}

export const requestLogin = async (email: string) => {
  return await postToApi('/propeasy/user/request_login', { email })
}

export const me = async () => {
  return await getFromApi('/propeasy/user/me')
}

export const refreshToken = async (session_token: string) => {
  return await postToApi('/propeasy/auth/refresh_token', { session_token })
}

export const retrieveTokenMarketInfo = async (tokenName: string) => {
  const data = await fetch('/api/token-info', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      token: tokenName,
    }),
  })
    .then((res) => res.json())
    .catch((err) => ({ supply: 0, price: 0, market_cap: 0 }))
  return data as TokenMarketInfoModel
}
