import type { PaletteMode, ThemeOptions } from '@mui/material'

const fontFamily = 'Inter Variable'
const typography = {
  fontFamily: fontFamily,
  h1: {
    fontFamily: fontFamily,
  },
  h2: {
    fontFamily: fontFamily,
  },
  h3: {
    fontFamily: fontFamily,
  },
  h4: {
    fontFamily: fontFamily,
  },
  h5: {
    fontFamily: fontFamily,
  },
  h6: {
    fontFamily: fontFamily,
    fontWeight: 700,
  },
}

const breakpoints = {
  values: {
    // xs: 0,
    // sm: 640,
    // md: 768,
    // // lg: 1024,
    // // xl: 1280,
    // lg: 1280,
    // xl: 1440,
    xs: 0,
    sm: 640,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
}

const settings = {
  light: {
    palette: {
      mode: 'light',
      background: {
        default: '#FFFFFF',
      },
      primary: {
        main: '#033C59',
      },
      secondary: {
        main: '#F5A302',
      },
      text: {
        primary: '#000000',
        secondary: '#F5A302',
      },
    },
    typography,
    breakpoints,
    components: {},
  },
  dark: {
    palette: {
      mode: 'dark',
      background: {
        default: '#112548',
      },
      primary: {
        main: '#1F5F8D',
      },
      secondary: {
        main: '#1F5F8D',
      },
      success: {
        main: '#55B964',
      },
      text: {
        primary: '#000000',
      },
    },
    typography,
    breakpoints,
    components: {},
  },
}

const getThemeOptions = (mode: PaletteMode) => settings[mode] as ThemeOptions

export default getThemeOptions
