import { useAnchorWallet, useConnection, useWallet } from '@solana/wallet-adapter-react'

/**
 * Custom hook to simplify the integration of the Renec wallet adapter functionalities in your application.
 *
 * @returns {{
 *   walletContext: any,
 *   anchorWallet: any,
 *   connectionContext: any
 * }} An object containing context and hook instances:
 * - `walletContext`: The context used to interact with the Renec wallet extension.
 * - `anchorWallet`: The custom hook to interact with the Renec Wallet Adapter and access wallet  connection details.
 * - `connectionContext`: The context used to interact with the Renec blockchain connection.
 *
 * @example
 * // Usage within a functional component
 * import { useDemonAdapter } from 'your-library-name';
 *
 * function MyComponent() {
 *   const { walletContext, anchorWallet, connectionContext } = useDemonAdapter();
 *
 *   // Your code here, using the walletContext, anchorWallet, and connectionContext as needed
 *
 *   return (
 *     <div>
 *       // Your UI elements
 *     </div>
 *   );
 * }
 */
export const useDemonAdapter = () => {
  const walletContext = useWallet()
  const anchorWallet = useAnchorWallet()
  const connectionContext = useConnection()

  return {
    walletContext,
    anchorWallet,
    connectionContext,
  }
}
