import moment from 'moment-timezone'
import Rollbar from 'rollbar'

import {
  EXPLORER_URL,
  MAINNET_RPC_URL,
  MAINNET_WSS_URL,
  TESTNET_RPC_URL,
  TESTNET_WSS_URL,
} from '../constants/index'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress: any
  }
}

export const isMainnet = process.env.NEXT_PUBLIC_IS_MAINNET === 'true'
export const isBeta = process.env.NEXT_PUBLIC_IS_BETA === 'true'
export const PROPEASY_SITE_URL = process.env.NEXT_PUBLIC_SITE_URL?.trim()
export const displayTest = isBeta ? '(Test)' : ''
export const displayChartTokens = process.env.NEXT_PUBLIC_SHOW_PRICE_CHART_TOKEN?.trim().split(',') ?? []

export const getTokenAddressInfo = (tokenSymbol: string) => {
  const checkValidToken = `${process.env.NEXT_PUBLIC_VALID_REQ_TOKEN_SYMBOL?.trim()}`
    .split(',')
    .includes(tokenSymbol)
  const poolAddress = process.env[`NEXT_PUBLIC_POOL_ADDRESS_${tokenSymbol}`]?.trim()
  const tokenAddress = process.env[`NEXT_PUBLIC_TOKEN_ADDRESS_${tokenSymbol}`]?.trim()
  return {
    poolAddress: checkValidToken ? `${poolAddress}` : '',
    tokenAddress: checkValidToken ? `${tokenAddress}` : '',
  }
}

export const rollbarServer = new Rollbar({
  accessToken: process.env.ROLLBAR_SERVER_TOKEN,
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'testenv',
  nodeSourceMaps: true,
})

export const getNetworkUrls = () =>
  isMainnet
    ? { rpc: MAINNET_RPC_URL, wss: MAINNET_WSS_URL }
    : { rpc: TESTNET_RPC_URL, wss: TESTNET_WSS_URL }

export const isRunningInCypress = typeof window !== 'undefined' && window.Cypress !== undefined
export const cypressWalletPrivate =
  isRunningInCypress ? process.env.NEXT_PUBLIC_CYPRESS_WALLET_PRIVATE_KEY : undefined

export const getTransactionDetailUrl = (txId: string) =>
  `${EXPLORER_URL}/tx/${txId}${isMainnet ? '' : '?cluster=testnet'}`

export const getMintAddressDetailUrl = (mintAccount: string) =>
  `${EXPLORER_URL}/address/${mintAccount}${isMainnet ? '' : '?cluster=testnet'}`

export const removeIntercomActiveClass = () => {
  document.querySelectorAll('.intercom-mobile-messenger-active').forEach((element) => {
    element.classList.remove('intercom-mobile-messenger-active')
  })
}

export const onIntercomWidgetShowed = () => {
  document.body.classList.add('intercom-h-0')
}

export const onIntercomWidgetHidden = () => {
  document.body.classList.remove('intercom-h-0')
}

export const detectElementMutated = (element: any, callback: () => void) => {
  const observer = new MutationObserver(callback)

  observer.observe(element, {
    attributes: true,
    attributeFilter: ['class'],
    childList: false,
    characterData: false,
  })
}

export const minifyAddress = (address: string, length = 4) => {
  const firstPart = address.slice(0, length)
  const lastPart = address.slice(-length)
  return `${firstPart}...${lastPart}`
}

export const isValidEmail = (email: string) => {
  const isValidEmail = /^([\w-+]+(?:\.[\w-]+)*)+@([\w-]+\.)+[\w-]{2,4}$/g
  return email && email.match(isValidEmail)
}

export const removeDiacritics = (str: string, lowerCase = false) => {
  const value = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  return lowerCase ? value.toLowerCase() : value
}

export const calSecondsToWeeks = (seconds: number) => {
  return Math.round(seconds / (7 * 24 * 60 * 60))
}

export const calSecondsToTime = (seconds: number) => {
  return new Date(seconds * 1000)
}

export const calQuantityOfTokens = (amountEntered: number, priceSale: number) => {
  if (priceSale === 0) return priceSale
  return parseFloat((amountEntered / priceSale).toFixed(9))
}

export const calPriceSale = (discountRate: number, tokenPrice: number) => {
  if (discountRate === 0) return tokenPrice
  return Number((tokenPrice * (1 - discountRate / 100)).toFixed(5))
}

export const formattedNumber = (number: number) => {
  return new Intl.NumberFormat('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 5,
  }).format(number)
}

export const convertToCurrency = (number: number) => {
  const divisor = number < 1000000000 ? 1000000 : 1000000000
  const result = number / divisor
  const unit = divisor === 1000000 ? 'million' : 'billion'

  return { value: result.toLocaleString(undefined, { maximumFractionDigits: 2 }), unit }
}

export const calculateHoursBetweenDates = (date1: Date, date2: Date): number => {
  const millisecondsDiff = date2.getTime() - date1.getTime()
  const hoursDiff = millisecondsDiff / (1000 * 60 * 60)
  return hoursDiff
}

export const convertToClientTime = (
  originalTime?: moment.MomentInput,
  format?: string,
): string | Date => {
  const userTimezone = moment.tz.guess(true)
  const clientTimeInUserTimezone = moment(originalTime).tz(userTimezone)

  if (format) return clientTimeInUserTimezone.format(format)
  return clientTimeInUserTimezone.toDate()
}

export const timeFormatString = (timeString: string, format: string) => String(convertToClientTime(timeString, format))

export const isValidCaraxAddress = (address?: string) => !!address && address.length > 40
