import React, { useMemo } from 'react'

import Image from 'next/image'

import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import { Link } from '@mui/material'

interface PropEasyLogoProps {
  iconOnly?: boolean
}

const PropEasyLogo = (props: PropEasyLogoProps) => {
  const { iconOnly } = props
  const propEasyLogo = useMemo(() => {
    return iconOnly
      ? staticImportIcon(IconName.PROPEASY_ICON)
      : staticImportIcon(IconName.PROPEASY_LOGO)
  }, [iconOnly])
  return (
    <Link href="/" data-testid="header-home-trade" className="no-underline flex items-center">
      <Image className="inline mr-2" src={propEasyLogo} height={32} alt={IconName.PROPEASY_LOGO} />
    </Link>
  )
}

export default PropEasyLogo
