import { useEffect, useRef, useMemo } from 'react'
import { useRouter } from 'next/router'
import type { SnackbarKey } from 'notistack'
import { useSnackbar } from 'notistack'
import { useSettings } from '@/hooks/use-settings'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Typography } from '@mui/material'

export default function SnackBar() {
  const { data: settingsData } = useSettings()
  const noticeMessages = settingsData?.notice_messages
  const { locale } = useRouter()
  const messages = useMemo(() => {
    return Object.keys(noticeMessages || {})
    .map((v) => noticeMessages[v] ? noticeMessages[v][locale || 'en'] : undefined)
    .filter((v) => v)
  }, [noticeMessages, locale])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const ref = useRef<string[]>([])

  useEffect(() => {
    if (Array.isArray(messages)) {
      let newSnackBar: string[] = []

      messages.forEach((msgData, idx) => {
        if (!ref.current.includes(msgData)) {
          newSnackBar = newSnackBar.concat(msgData)
          enqueueSnackbar(<Typography className="text-sm pr-6">{msgData}</Typography>, {
            variant: 'default',
            preventDuplicate: true,
            action: (key: SnackbarKey) => (
              <IconButton
                data-testid={`close-snack-bar-${idx + 1}`}
                size="small"
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={() => closeSnackbar(key)}
              >
                <CloseIcon />
              </IconButton>
            ),
          })

          ref.current = newSnackBar
        }
      })
    }
  }, [messages, enqueueSnackbar, closeSnackbar])

  return null
}
