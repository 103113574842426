import Cookies from 'js-cookie'
import { getFromApi, postToApi } from '.'
import { isValidCaraxAddress } from '../helpers'

export const updateReferralAddress = async (referee_address: string) => {
  const referrer_address = Cookies.get('referral_code')
  if (isValidCaraxAddress(referrer_address)) {
    return await postToApi('/propeasy/referral', {
      referrer_address,
      referee_address,
    })
  }
}

export const fetchDataReferral = async (address: string) => {
  return await getFromApi(`/propeasy/referral/${address}`)
}
