import api from '@/utils/apis/index'
import { KycVerifications } from '@/model/kyc-verifications'

export const fetchKycVerifications = async (
  address?: string,
): Promise<KycVerifications | undefined> => {
  if (!address) return

  const { data } = await api.get('/kyc_verifications/status', {
    params: {
      wallet_address: address,
    },
    validateStatus: function (status) {
      return status < 500 // Resolve only if the status code is less than 500
    },
  })
  return data
}
