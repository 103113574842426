import { ReactElement, createContext, useMemo, useEffect, useState } from 'react'
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material'
import type { PaletteMode, ThemeOptions } from '@mui/material'
import getThemeOptions from './options'
import useLocalStorage from '@/hooks/useLocalStorage'

interface ThemeContextValues {
  themeMode: PaletteMode;
  themeOptions: ThemeOptions;
  toggleThemeMode: (newMode?: PaletteMode) => void;
}

const ThemeContext = createContext({} as ThemeContextValues)

const localStorageThemeModeKeyName = 'THEME_MODE'

function Provider({ children }: HocProps): ReactElement {
  const preferredMode = 'light'
  const [savedThemeMode, setSavedThemeMode] = useLocalStorage(localStorageThemeModeKeyName, preferredMode)
  const [mode, setMode] = useState((savedThemeMode as PaletteMode) ?? preferredMode)

  const contextValues = useMemo(() => {
    const themeOptions = createTheme(getThemeOptions(mode))
    return {
      themeMode: mode,
      themeOptions,
      toggleThemeMode: (newMode?: PaletteMode) => {
        setMode(
          (prevMode: PaletteMode) =>
            (newMode as PaletteMode) ?? (prevMode === 'light' ? 'dark' : 'light'),
        )
      },
    }
  }, [mode])

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', mode)
    if (mode === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
    setSavedThemeMode(mode)
  }, [mode, setSavedThemeMode])

  return (
    <ThemeContext.Provider value={contextValues}>
      <ThemeProvider theme={contextValues.themeOptions}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default Provider
