import React from 'react'

import { type Translation } from '../helpers'

interface WalletModalContextState {
  visible: boolean
  setVisible: (open: boolean) => void
  trans: Translation
}

const DEFAULT_CONTEXT = {
  setVisible(_open: boolean) {
    // eslint-disable-next-line no-console
    console.error(constructMissingProviderErrorMessage('call', 'setVisible'))
  },
  visible: false,
}
Object.defineProperty(DEFAULT_CONTEXT, 'visible', {
  get() {
    // eslint-disable-next-line no-console
    console.error(constructMissingProviderErrorMessage('read', 'visible'))
    return false
  },
})

function constructMissingProviderErrorMessage(action: string, valueName: string) {
  return (
    'You have tried to ' +
    ` ${action} "${valueName}"` +
    ' on a WalletModalContext without providing one.' +
    ' Make sure to render a WalletModalProvider' +
    ' as an ancestor of the component that uses ' +
    'WalletModalContext'
  )
}

export const WalletModalContext = React.createContext<WalletModalContextState>(
  DEFAULT_CONTEXT as WalletModalContextState,
)

export function useWalletModal(): WalletModalContextState {
  return React.useContext(WalletModalContext)
}
