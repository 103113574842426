import React, { useCallback, useMemo, useState } from 'react'
import { Stack, Typography, Grid, Button } from '@mui/material'
import PropEasyLogo from '@/components/base/PropEasyLogo'
import { useDemonAdapter, useWalletModal } from '../hooks'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import Image from 'next/image'
import bs58 from 'bs58'

const MessageItem = (props: { icon?: string; text: string }) => {
  const { icon, text } = props
  return (
    <Typography className="text-[#E7E8EB] text-sm">
      {icon && <Image className="inline mr-3" src={staticImportIcon(icon)} alt={icon} width={24} />}
      {text}
    </Typography>
  )
}

function WalletAccept(props: { handleCloseNotice: () => void; handleConfirmNotice: () => void }) {
  const { handleCloseNotice, handleConfirmNotice } = props
  const { trans } = useWalletModal()
  const [isConnected, setIsConnected] = useState(false)
  const [message, setMessage] = useState('')
  const { walletContext } = useDemonAdapter()

  const stackMessage = useMemo(() => {
    return !isConnected ? (
      <>
        <MessageItem icon={IconName.CHECK_PICK_ICON} text={trans.wallet_accept_transaction} />
        <MessageItem icon={IconName.CHECK_PICK_ICON} text={trans.wallet_accept_request_approve} />
      </>
    ) : (
      <>
        <Typography className="text-[#21D969] text-sm font-bold text-center">
          {trans.wallet_accept_message}
        </Typography>
        <Typography className="text-[#E7E8EB] text-sm text-center">{message}</Typography>
      </>
    )
  }, [trans, isConnected, message])

  const handleConnect = useCallback(async () => {
    if (!walletContext || !walletContext.publicKey || !walletContext.signMessage) return
    const encodedMsg = new TextEncoder().encode('Propeasy')
    const signedMessage = await walletContext.signMessage(encodedMsg)
    const signedMessageBase58 = bs58.encode(Buffer.from(signedMessage))
    setMessage(signedMessageBase58)
    setIsConnected(true)
  }, [walletContext])

  return (
    <Stack className="bg-ps-dark-blue p-6 sm:max-w-sm w-full" alignItems="center" gap={3}>
      <PropEasyLogo />
      <Typography className="text-[#FFF] text-center font-bold text-lg">
        {trans.wallet_accept_title}
      </Typography>
      <Typography className="text-[#D0D1D8] text-base">{trans.wallet_accept_domain}</Typography>

      <Stack className="bg-[#3A3F5D] rounded-lg py-4 px-3 w-full" gap={1}>
        {stackMessage}
      </Stack>

      <Typography className="text-[#888C9E] text-sm">{trans.wallet_accept_notice}</Typography>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Button
            fullWidth
            className="bg-ps-dark-blue hover:bg-ps-primary border border-solid border-[#888C9E] text-base font-semibold text-[#E7E8EB] normal-case rounded-lg py-3"
            onClick={handleCloseNotice}
          >
            {trans.wallet_accept_cancel}
          </Button>
        </Grid>
        <Grid item xs={7}>
          <Button
            fullWidth
            className="bg-[#21D969] hover:bg-[#57e08b] border border-solid border-[#21D969] text-base font-semibold text-ps-dark-blue normal-case rounded-lg py-3"
            onClick={!isConnected ? handleConfirmNotice : handleConnect}
          >
            {!isConnected ? trans.wallet_accept_connect : trans.wallet_accept_approve}
          </Button>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default WalletAccept
