import { Stack, Rating, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useRouter } from 'next/router'
import React from 'react'

const RatingAnswer = ({ data }: any) => {
  const { locale } = useRouter()
  const { control, formState } = useFormContext()
  const { question } = data[locale || 'vi']
  const hasError = !!formState.errors[data.id]

  return (
    <Stack gap={2} alignItems="center">
      <Typography className={`text-sm font-bold ${hasError && 'text-red-600'}`}>
        {`${data.id.replace('_', '.')} ${question} ${data.required ? '*' : ''}`}
      </Typography>
      <Controller
        name={data.id}
        control={control}
        render={({ field }) => <Rating {...field} max={10} size="large" />}
        rules={{
          required: data.required,
        }}
      />
    </Stack>
  )
}

export default RatingAnswer
