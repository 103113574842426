import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios'
import Cookies from 'js-cookie'

import { refreshToken as apiRefreshToken } from './helper'
import {
  CurrencyData,
  ResponseData,
} from './types'

const AUTHENTICATION_KEY = 'Access-Token'
export const TOKEN_KEY = '_s_ad'
export const REFRESH_TOKEN_KEY = '_s_r_ad'
export const MAX_AGE_TOKEN = 1 // 1 days
export const MAX_AGE_REFRESH_TOKEN = 7 // 7 days

export const BASE_URL = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1`

const api = axios.create({
  timeout: 60000,
  baseURL: BASE_URL,
})

api.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    } as AxiosRequestHeaders

    const country: CurrencyData = JSON.parse(decodeURIComponent(Cookies.get('country') || '{}'))
    config.headers['X-Propeasy-Location'] = (country.country?.isoCode || 'VN').toLowerCase()
    config.headers['X-Propeasy-Locale'] = (
      Cookies.get('locale') ||
      country.lang ||
      'en'
    ).toLowerCase()
    const accessToken = Cookies.get(TOKEN_KEY)
    if (accessToken) config.headers[AUTHENTICATION_KEY] = `${accessToken}`
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      const { status } = error.response
      const refreshToken = Cookies.get(REFRESH_TOKEN_KEY)
      if (status === 401 && refreshToken) {
        const resRefreshToken = await apiRefreshToken(refreshToken)
        if (resRefreshToken.success) {
          const { access_token: newToken } = resRefreshToken.data
          Cookies.set(TOKEN_KEY, newToken, { expires: MAX_AGE_TOKEN })
          const config = error.response.config
          config.headers[AUTHENTICATION_KEY] = `${newToken}`
          return api(config)
        }
      }
      return Promise.reject(error.response)
    }
    return Promise.reject(error)
  },
)
export default api

export const doInternalRequest = async (
  action: string,
  method: 'get' | 'post' | 'options' | 'put',
  data?: object,
  config?: AxiosRequestConfig,
) => {
  const requestMethod = (method || 'get').toUpperCase()
  try {
    const result = await api.request({ url: action, method: requestMethod, data: data, ...config })
    return { success: true, message: result.statusText, data: result.data } as ResponseData
  } catch (error: any) {
    return { success: false, message: error.statusText, data: error.data } as ResponseData
  }
}

export const getFromApi = async (action: string, config?: AxiosRequestConfig) => {
  return await doInternalRequest(action, 'get', undefined, config)
}
export const postToApi = async (action: string, data?: object, config?: AxiosRequestConfig) => {
  return await doInternalRequest(action, 'post', data, config)
}
