const transObj = {
  en: {
    close: 'Close',
    connect_wallet: 'Connect wallet',
    disconnect: 'Disconnect wallet',
    copied: 'Copied',
    copy_address: 'Copy address',
    connect: 'Connect',
    connecting: 'Connecting',
    connected: 'Connected',
    change_wallet: 'Change wallet',
    dialog_heading: 'Connect a wallet to continue',
    first_time_connect_notice: 'For first time connect, you might need to Create Wallet first.',
    install_demon_notice_heading: 'Install Demon wallet',
    install_demon_notice_body:
      'It will take you to a new website to install the Carax Demon Wallet. Once the installation is complete, please refresh the website.',
    detected: 'Detected',
    not_detected: 'Not detected',
    connect_using_demon_app: 'Connect using the Carax Demon Wallet application ?',
    cancel: 'Cancel',
    wallet_not_yet: "You don't have a wallet yet?",
    learn_how_to_connect: 'Learn how to connect',
    wallet_accept_title: 'Propeasy | Invest easily in tokens backed by real estates',
    wallet_accept_domain: 'www.propeasy.renec.org',
    wallet_accept_notice: 'Only connect to website that you trust',
    wallet_accept_connect: 'Connect',
    wallet_accept_approve: 'Approve',
    wallet_accept_cancel: 'Cancel',
    wallet_accept_transaction: 'View balance and activities',
    wallet_accept_request_approve: 'Request transaction approval',
    wallet_accept_message: 'Approve message',
    transaction_history: 'Transaction history',
    email_description:
      'Để đủ điều kiện tham gia vào các đợt mở bán tài sản độc quyền và cập nhật những tính năng, tài sản mới nhất qua bản tin của chúng tôi.',
    email_active_title: 'Liên kết email',
    email_input: 'Nhập vào email của bạn',
    email_link: 'Liên kết',
    email_confirm: 'Xác thực email',
    email_confirm_description:
      'Chúng tôi đã gửi liên kết xác minh vào email của bạn. Vui lòng kiểm tra email và làm theo hướng dẫn để hoàn tất xác thực email.',
    email_confirm_done: 'Đã hiểu',
    notice: 'Rewards will be sent to your wallet by <strong>23:59 PM 11/30/2023 (GMT+7)</strong>.',
    continue_kyc: 'KYC now',
    get_award: 'Get reward',
    awarded: 'Reward claimed',
    kyc_now: 'KYC now',
    kyc_help:
      'Your wallet address hasn\'t completed KYC yet. <a class="text-ps-secondary underline" href="https://renec.org/blog/ra-mat-renecid" target="_blank">Read instructions</a> to complete KYC and receive rewards.',
    share_notice:
      'Share the referral code with your friends so you can all receive PROP rewards when your friends buy real-estate tokens! <a class="text-ps-secondary underline" href="#" target="_blank">Details</a>',
    share_my_referral: 'Share referral code',
    share_other: 'Or share via',
    share: 'Share',
    beta: {
      claim_token_test: 'Receive token tests',
      airdrop_success: 'You have received the test tokens.',
      airdrop_error: {
        recaptcha_failed: 'It seems there was an issue with the verification. Please try again.',
        unknown_error: 'An error occurred',
        bad_request: 'Invalid',
        exceed_airdrop_amount:
          'The tokens test balance in your wallet exceed the amount that can be received.',
      },
    },
    your_referrer: 'Your referrer:',
    no_referrer: 'You haven’t had any referrer yet',
    tooltip_referrer:
      'To successfully record your referrer:<br/><br/>1. Your referrer needs to send you their referral code.<br/><br/>2. After that, you need to connect wallet successfully and the system will record the referrer for you.',
  },
  vi: {
    close: 'Đóng',
    connect_wallet: 'Kết nối ví',
    disconnect: 'Ngắt kết nối ví',
    copied: 'Đã copy',
    copy_address: 'Copy địa chỉ',
    connect: 'Kết nối',
    connecting: 'Đang kết nối',
    connected: 'Đã kết nối',
    change_wallet: 'Thay đổi ví',
    dialog_heading: 'Kết nối ví để tiếp tục',
    first_time_connect_notice: 'Ở lần kết nối đầu tiên, bạn có thể sẽ cần Tạo Ví trước.',
    install_demon_notice_heading: 'Cài đặt ví Demon',
    install_demon_notice_body:
      'Bạn sẽ được đưa tới trang mới để cài đặt ví Carax Demon. Sau khi hoàn tất cài đặt, vui lòng tải lại website.',
    detected: 'Đã cài đặt',
    not_detected: 'Không tìm thấy',
    connect_using_demon_app: 'Kết nối với ứng dụng Carax Demon Wallet ?',
    cancel: 'Huỷ bỏ',
    wallet_not_yet: 'Bạn chưa có ví?',
    learn_how_to_connect: 'Tìm hiểu cách để kết nối',
    wallet_accept_title: 'Propeasy | Đầu tư dễ dàng vào các token bất động sản trên blockchain',
    wallet_accept_domain: 'www.propeasy.renec.org',
    wallet_accept_notice: 'Lưu ý chí kết nối với website bạn tin tưởng',
    wallet_accept_connect: 'Kết nối',
    wallet_accept_approve: 'Chấp thuận',
    wallet_accept_cancel: 'Hủy',
    wallet_accept_transaction: 'Xem số dư và hoạt động',
    wallet_accept_request_approve: 'Yêu cầu phê duyệt giao dịch',
    wallet_accept_message: 'Chấp thuận message',
    transaction_history: 'Lịch sử giao dịch',
    email_description:
      'Để đủ điều kiện tham gia vào các đợt mở bán tài sản độc quyền và cập nhật những tính năng, tài sản mới nhất qua bản tin của chúng tôi.',
    email_active_title: 'Liên kết email',
    email_input: 'Nhập vào email của bạn',
    email_link: 'Liên kết',
    email_confirm: 'Xác thực email',
    email_confirm_description:
      'Chúng tôi đã gửi liên kết xác minh vào email của bạn. Vui lòng kiểm tra email và làm theo hướng dẫn để hoàn tất xác thực email.',
    email_confirm_done: 'Đã hiểu',
    notice: 'Phần thưởng sẽ được gửi vào ví của bạn trước <strong>23h59 ngày 30/11/2023</strong>.',
    continue_kyc: 'Xác thực ngay',
    get_award: 'Nhận thưởng',
    awarded: 'Đã nhận thưởng',
    kyc_now: 'KYC ngay',
    kyc_help:
      'Địa chỉ ví của bạn chưa được KYC để nhận thưởng. <a class="text-ps-secondary underline" href="https://renec.org/blog/ra-mat-renecid" target="_blank">Xem hướng dẫn</a> để hoàn thành KYC và nhận thưởng.',
    share_notice:
      'Chia sẻ mã giới thiệu tới bạn bè để tất cả cùng nhận thưởng PROP khi bạn bè tham gia mua token bất đổng sản nhé! <a class="text-ps-secondary underline" href="#" target="_blank">Xem chi tiết</a>',
    share_my_referral: 'Chia sẻ mã giới thiệu',
    share_other: 'Hoặc chia sẻ qua',
    share: 'Chia sẻ',
    beta: {
      claim_token_test: 'Nhận tokens test',
      airdrop_success: 'Ví của bạn đã được nhận token test.',
      airdrop_error: {
        recaptcha_failed: 'Xảy ra lỗi trong quá trình xác thực. Vui lòng thử lại sau.',
        unknown_error: 'Đã xảy ra lỗi',
        bad_request: 'Không hợp lệ',
        exceed_airdrop_amount: 'Token test trong ví của bạn vượt quá số lượng có thể nhận.',
      },
    },
    your_referrer: 'Tuyến trên của bạn:',
    no_referrer: 'Bạn chưa có tuyến trên',
    tooltip_referrer:
      'Để ghi nhận tuyến trên thành công:<br/><br/>1. Tuyến trên của bạn cần gửi liên kết giới thiệu của họ cho bạn.<br/><br/>2. Sau đó, bạn cần kết nối ví thành công và hệ thống sẽ ghi nhận tuyến trên cho bạn.',
  },
}

export type Translation = (typeof transObj)['en']
export type GetTranslationsParams = {
  locale?: keyof typeof transObj
  customTransObj?: Partial<Translation>
}

export const getTranslations = ({ locale, customTransObj }: GetTranslationsParams) => {
  const trans = transObj[locale || 'en'] || transObj.vi

  return Object.assign(trans, customTransObj || {})
}
