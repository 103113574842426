import { useQuery } from 'react-query'
import { Connection, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js'
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { isMainnet, isBeta, getNetworkUrls } from '@/utils/helpers'
import { PROP, PROP_TESTNET, REUSD, REUSD_TESTNET } from '@renec-foundation/propeasy-sdk'
import { BalanceModel } from '@/model/wallet-detail'
import { getTransactionsByBuyerAddress } from '@/utils/apis/property'
import { fetchDataReferral } from '@/utils/apis/referral'

const fetchAtaBalanceInfo = async (connection: Connection, owner: PublicKey, mint: PublicKey) => {
  const ataInfo: BalanceModel = { amount: '0', uiAmount: 0, uiAmountString: '0' }
  try {
    const ataProp = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mint,
      owner,
      false,
    )
    const ataBalanceInfo = await connection.getTokenAccountBalance(ataProp)
    ataInfo.amount = ataBalanceInfo.value.amount
    ataInfo.uiAmount = ataBalanceInfo.value.uiAmount || 0
    ataInfo.uiAmountString = ataBalanceInfo.value.uiAmountString || '0'
  } catch {}
  return ataInfo
}

export const useFetchAssociatedTokenAddress = (publicKey?: string) => {
  return useQuery(
    ['fetchAssociatedTokenAddress', publicKey],
    async () => {
      if (!publicKey) return null
      const owner = new PublicKey(publicKey)
      const rpcEndpoint = getNetworkUrls().rpc
      const connection = new Connection(rpcEndpoint, 'confirmed')

      // PROP
      const mintTokenAccountProp = isMainnet && !isBeta ? PROP : PROP_TESTNET
      const prop: BalanceModel = await fetchAtaBalanceInfo(connection, owner, mintTokenAccountProp)

      // reUSD
      const mintTokenAccountReUsd = isMainnet && !isBeta ? REUSD : REUSD_TESTNET
      const reUsd: BalanceModel = await fetchAtaBalanceInfo(connection, owner, mintTokenAccountReUsd)

      // RENEC
      const renec = await connection.getBalance(owner)

      let referral: PublicKey | undefined
      try {
        const { success, data } = await fetchDataReferral(publicKey)
        if (success && data.data) {
          referral = !!data.data?.referrer_address
            ? new PublicKey(data.data?.referrer_address)
            : undefined
        }
      } catch {}

      return { prop, reUsd, renecBalance: renec * 1.0 / LAMPORTS_PER_SOL, referral }
    },
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useFetchTransactionsByBuyerAddress = (
  address?: string,
  searchText?: string,
  saleType?: string,
) => {
  return useQuery(
    ['fetchTransactionsByBuyerAddress', address, searchText, saleType],
    async () => {
      if (address) return await getTransactionsByBuyerAddress(address, searchText, saleType)
      return null
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
}
