import { useMemo } from 'react'
import { createEmotionCache } from '@/utils/createEmotionCache'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { AppProps } from 'next/app'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { SnackbarProvider } from 'notistack'
import { IntercomProvider } from '@thanhvo102/react-use-intercom'
import { Provider as WalletProvider } from '@/wallet/providers'
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import {
  onIntercomWidgetShowed,
  onIntercomWidgetHidden,
  isRunningInCypress,
  isMainnet,
  cypressWalletPrivate,
} from '@/utils/helpers'

import NextNProgress from 'nextjs-progressbar'
import ThemeProvider from '@/providers/theme'
import UserStoreProvider from '@/providers/user-store'
import BootIntercom from '@/pages/_bootIntercom'
import Head from 'next/head'
import PropTypes from 'prop-types'
import LanguageProvider from '@/providers/language'
import SnackBar from '@/providers/notification'

import '@/root/src/styles/global.scss'
import '@/wallet/style.css'
import '@fontsource-variable/inter'
import '@fontsource-variable/roboto-condensed'
import Survey from '@/components/survey'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache: EmotionCache
}

const INTERCOM_APP_ID = isRunningInCypress
  ? Cypress.env('INTERCOM_APP_ID')
  : process.env.NEXT_PUBLIC_INTERCOM_APP_ID || ''

const rollbarConfig = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'testenv',
  nodeSourceMaps: true,
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps, router } = props
  const queryClient = useMemo(() => new QueryClient(), [])
  const locale = router.locale as 'vi' | 'en' | undefined

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>Propeasy</title>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
          </Head>
          <IntercomProvider
            appId={INTERCOM_APP_ID}
            apiBase={`https://${INTERCOM_APP_ID}.intercom-messenger.com`}
            widgetUrl={'https://renec.foundation/intercom-shim.latest.js'}
            onHide={onIntercomWidgetHidden}
            onShow={onIntercomWidgetShowed}
          >
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                <ThemeProvider>
                  <LanguageProvider>
                    <WalletProvider isMainnet={isMainnet} locale={locale} e2eWalletPrivKey={cypressWalletPrivate}>
                      <UserStoreProvider>
                        <SnackbarProvider maxSnack={3} className="snackbar-container">
                          { process.env.NEXT_PUBLIC_SHOW_SURVEY === 'true' && <Survey /> }
                          <BootIntercom />
                          <NextNProgress color="#f5a302" />
                          <Component {...pageProps} />
                          <ReactQueryDevtools initialIsOpen={false} />
                          <SnackBar />
                        </SnackbarProvider>
                      </UserStoreProvider>
                    </WalletProvider>
                  </LanguageProvider>
                </ThemeProvider>
              </Hydrate>
            </QueryClientProvider>
          </IntercomProvider>
        </CacheProvider>
      </ErrorBoundary>
    </RollbarProvider>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
}
