export const MAINNET_RPC_URL = 'https://api-mainnet-beta.renec.foundation:8899'
export const MAINNET_WSS_URL = 'wss://api-mainnet-beta.renec.foundation:8900'
export const TESTNET_RPC_URL = 'https://api-testnet.renec.foundation:8899'
export const TESTNET_WSS_URL = 'wss://api-testnet.renec.foundation:8900'
export const EXPLORER_URL = 'https://explorer.renec.foundation'
export const TRUSTPILOT_REVIEW_URL = 'https://www.trustpilot.com/review/propeasy.org'
export const SITE_URL = process.env.NEXT_PUBLIC_IS_BETA === 'true' ? 'https://test.propeasy.org' : 'https://propeasy.org'
export const NEMO_SITE_URL = 'https://nemo.renec.org'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm'
export const DATE_TIME_FORMAT_12_HOURS = 'DD-MM-YYYY hh:mmA'
export const DATE_TIME_FORMAT_HH = 'HH:mm DD-MM-YYYY'
export const DATE_TIME_FORMAT_SLASH = 'HH:mm DD/MM/YYYY'
export const DATE_MONTH = 'DD/MM'
export const HOUR_START_COUNT_DOWN = 2
export const DEFAULT_PAGE = 1
export const DEFAULT_PER_PAGE = 10
export const LEADERBOARD_REWARD_AMOUNT = 50
