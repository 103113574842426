import { Button } from '@mui/material'
import React from 'react'

interface PropEasyButtonProps {
  text: string | React.ReactElement
  handleClick: (...args: any) => void
  rounded?: boolean
  secondary?: boolean | false
  disabled?: boolean | false
  basic?: boolean | false
}
function PropEasyButton(props: PropEasyButtonProps) {
  const { text, handleClick, rounded, secondary, disabled, basic } = props

  return (
    <Button
      onClick={handleClick}
      disableRipple
      fullWidth
      disabled={disabled}
      className={`${
        basic
          ? 'bg-white hover:text-ps-secondary text-ps-primary border-2 border-solid border-ps-primary'
          : secondary
          ? 'bg-ps-secondary hover:bg-[#DC9201] text-ps-primary'
          : 'bg-ps-primary hover:bg-ps-dark-blue text-ps-secondary'
      } disabled:opacity-40 font-bold text-base normal-case rounded-${
        rounded ? 'full' : 'lg'
      } py-4`}
    >
      {text}
    </Button>
  )
}

export default PropEasyButton
