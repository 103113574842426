import { useRouter } from 'next/router'
import en from '@/locales/en'
import vi from '@/locales/vi'

const useTrans = () => {
  const { locale } = useRouter()

  switch (locale) {
    case 'vi':
      return vi
    default:
      return en
  }
}

export default useTrans
