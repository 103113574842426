import { useQuery } from 'react-query'
import { checkSurveyCampaign, checkTokenBought } from '@/utils/apis/survey'

export const useCheckSurveyCampaign = (address?: string) => {
  return useQuery(
    ['checkSurveyCampaign', address],
    async () => {
      if (address) return await checkSurveyCampaign(address)
      return null
    },
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useCheckTokenBought = (address?: string) => {
  return useQuery(
    ['checkTokenBought', address],
    async () => {
      if (address) return await checkTokenBought(address)
      return null
    },
    {
      refetchOnWindowFocus: false,
    },
  )
}
