import api from '@/utils/apis/index'

const CAMPAIGN_NAME = 'GAST'

export const checkSurveyCampaign = async (address: string) => {
  const { data } = await api.get(
    `/propeasy/survey?wallet_address=${address}&campaign=${CAMPAIGN_NAME}`,
  )
  return data
}

export const checkTokenBought = async (address: string) => {
  const { data } = await api.get(
    `/propeasy/property_transactions/bought?buyer_address=${address}&token_symbol=${CAMPAIGN_NAME}`,
  )
  return data
}

export const submitSurvey = async (address: string, response: any) => {
  const { data } = await api.post('/propeasy/survey/submit_survey', {
    wallet_address: address,
    response: {
      campaign: CAMPAIGN_NAME,
      ...response,
    },
  })
  return data
}
