import type { FC, ReactNode } from 'react'
import React, { useState } from 'react'

import { WalletModal, type WalletModalProps } from '../components/WalletModal'
import { getTranslations, type GetTranslationsParams } from '../helpers'
import { WalletModalContext } from '../hooks'
import { type Translation } from '../helpers'
export interface WalletModalProviderProps extends WalletModalProps, GetTranslationsParams {
  children: ReactNode
}

export const WalletModalProvider: FC<WalletModalProviderProps> = ({
  children,
  locale,
  customTransObj,
  ...props
}) => {
  const [visible, setVisible] = useState(false)
  const trans: Translation = getTranslations({ locale, customTransObj })

  return (
    <WalletModalContext.Provider
      value={{
        visible,
        setVisible,
        trans,
      }}
    >
      {children}
      {visible && <WalletModal {...props} />}
    </WalletModalContext.Provider>
  )
}
