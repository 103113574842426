import LangEnIcon from '@/assets/lang-en-icon.png'
import LangViIcon from '@/assets/lang-vi-icon.png'
import LeaderboardIcon from '@/assets/leaderboard-icon.png'
import ProjectIcon from '@/assets/project-icon.png'
import PropEasyBlueLogo from '@/assets/propeasy-logo-blue.svg'
import PropEasyLogo from '@/assets/propeasy-logo.svg'
import AddCirlceIcon from '@/assets/svgs/add-cirlce-icon.svg'
import AreaIcon from '@/assets/svgs/area-icon.svg'
import ArrowRightIcon from '@/assets/svgs/arrow-right.svg'
import BackIcon from '@/assets/svgs/back-icon.svg'
import CheckCircleNoIcon from '@/assets/svgs/check-circle-no.svg'
import CheckCircleYesIcon from '@/assets/svgs/check-circle-yes.svg'
import CheckMarkDoneIcon from '@/assets/svgs/check-mark-done-icon.svg'
import CheckMarkIcon from '@/assets/svgs/check-mark.svg'
import CheckPickIcon from '@/assets/svgs/check-pick-icon.svg'
import CopyIcon from '@/assets/svgs/copy-icon.svg'
import CopyLightIcon from '@/assets/svgs/copy-light-icon.svg'
import MenuDocumentBlackIcon from '@/assets/svgs/document-black-icon.svg'
import MenuDocumentIcon from '@/assets/svgs/document-icon.svg'
import FacebookDarkIcon from '@/assets/svgs/facebook-logo-dark.svg'
import FiatPropIcon from '@/assets/svgs/fiat-prop-icon.svg'
import GlobalBlackIcon from '@/assets/svgs/global-black-icon.svg'
import GlobalIcon from '@/assets/svgs/global-icon.svg'
import HistoryIcon from '@/assets/svgs/history-icon.svg'
import HomeColorIcon from '@/assets/svgs/home-color-icon.svg'
import HomeIcon from '@/assets/svgs/home-icon.svg'
import InfoCircleIcon from '@/assets/svgs/info-circle-icon.svg'
import InfoIcon from '@/assets/svgs/info-icon.svg'
import LastBuyerRewardIcon from '@/assets/svgs/last-buyer-reward-icon.svg'
import LockedIcon from '@/assets/svgs/locked-icon.svg'
import MinusCirlceIcon from '@/assets/svgs/minus-cirlce-icon.svg'
import MissionAwardIcon from '@/assets/svgs/mission-award-icon.svg'
import MissionBoxIcon from '@/assets/svgs/mission-box-icon.svg'
import MissionCertIcon from '@/assets/svgs/mission-cert-icon.svg'
import MissionIcon from '@/assets/svgs/mission-icon.svg'
import OpenLinkIcon from '@/assets/svgs/open-link-icon.svg'
import PlayVideoIcon from '@/assets/svgs/play-video-icon.svg'
import ProgressBarImage from '@/assets/svgs/progress-bar-image.svg'
import PropEasyIcon from '@/assets/svgs/propeasy-icon.svg'
import MenuPropertiesBlackIcon from '@/assets/svgs/properties-black-icon.svg'
import MenuPropertiesIcon from '@/assets/svgs/properties-icon.svg'
import ReferralIcon from '@/assets/svgs/referral-icon.svg'
import RenecAwardsIcon from '@/assets/svgs/renec-awards-icon.svg'
import RenecBlackLogo from '@/assets/svgs/renec-black-logo.svg'
import RenecIcon from '@/assets/svgs/renec-icon.svg'
import RenecLogo from '@/assets/svgs/renec-logo.svg'
import SearchIcon from '@/assets/svgs/search-icon.svg'
import ShareIcon from '@/assets/svgs/share-icon.svg'
import SignOutIcon from '@/assets/svgs/sign-out-icon.svg'
import SlideNextIcon from '@/assets/svgs/slide-next.svg'
import SlidePreviousIcon from '@/assets/svgs/slide-previous.svg'
import SocialDiscord from '@/assets/svgs/social-discord.svg'
import SocialFacebook from '@/assets/svgs/social-facebook.svg'
import SocialTelegram from '@/assets/svgs/social-telegram.svg'
import SocialTwitter from '@/assets/svgs/social-twitter.svg'
import SurveyCompletedIcon from '@/assets/svgs/survey-complete.svg'
import TelegramDarkIcon from '@/assets/svgs/telegram-dark.svg'
import TimerIcon from '@/assets/svgs/timer-icon.svg'
import TrustpilotLogo from '@/assets/svgs/trustpilot-logo.svg'
import TrustpilotStar from '@/assets/svgs/trustpilot-star.svg'
import TwitterDarkIcon from '@/assets/svgs/twitter-dark.svg'
import VerifiedIcon from '@/assets/svgs/verified-icon.svg'
import VerifyIcon from '@/assets/svgs/verify-icon.svg'
import WalletIcon from '@/assets/svgs/wallet-icon.svg'
import TotalValuationIcon from '@/assets/total-valuation-icon.png'

export const IconName = {
  PROPEASY_LOGO: 'propeasy-logo',
  PROPEASY_BLUE_LOGO: 'propeasy-blue-logo',
  PROPEASY_ICON: 'propeasy-icon',
  MENU_PROPERTIES_ICON: 'properties-icon',
  MENU_PROPERTIES_BLACK_ICON: 'properties-black-icon',
  MENU_DOCUMENT_ICON: 'document-icon',
  MENU_DOCUMENT_BLACK_ICON: 'document-black-icon',
  SOCIAL_FACEBOOK: 'social-facebook',
  SOCIAL_TWITTER: 'social-twitter',
  SOCIAL_DISCORD: 'social-discord',
  SOCIAL_TELEGRAM: 'social-telegram',
  HOME_ICON: 'home-icon',
  SEARCH_ICON: 'search-icon',
  AREA_ICON: 'area-icon',
  INFO_ICON: 'info-icon',
  INFO_CIRCLE_ICON: 'info-circle-icon',
  OPEN_LINK_ICON: 'open-link-icon',
  CHECK_PICK_ICON: 'check-pick-icon',
  ARROW_RIGHT_ICON: 'arrow-right-icon',
  COPY_ICON: 'copy-icon',
  HISTORY_ICON: 'history-icon',
  VERIFY_ICON: 'verify-icon',
  VERIFIED_ICON: 'verified-icon',
  ADD_CIRLCE_ICON: 'add-cirlce-icon',
  MINUS_CIRLCE_ICON: 'minus-cirlce-icon',
  REFERRAL_ICON: 'referral-icon',
  CHECK_MARK_DONE_ICON: 'check-mark-done-icon',
  MISSION_ICON: 'mission-icon',
  MISSION_BOX_ICON: 'mission-box-icon',
  MISSION_CERT_ICON: 'mission-cert-icon',
  MISSION_AWARD_ICON: 'mission-award-icon',
  FACEBOOK_DARK_ICON: 'facebook-logo-dark',
  TWITTER_DARK_ICON: 'twitter-dark',
  TELEGRAM_DARK_ICON: 'telegram-dark',
  COPY_LIGHT_ICON: 'copy-light-icon',
  PLAY_VIDEO_ICON: 'play-video-icon',
  RENEC_LOGO: 'renec-logo',
  RENEC_BLACK_LOGO: 'renec-black-logo',
  FIAT_PROP_ICON: 'fiat-prop-icon',
  SURVEY_COMPLETED_ICON: 'survey-complete',
  GLOBAL_ICON: 'global-icon',
  GLOBAL_BLACK_ICON: 'global-black-icon',
  SIGN_OUT_ICON: 'sign-out-icon',
  SLIDE_NEXT: 'slide-next',
  SLIDE_PREVIOUS: 'slide-previous',
  BACK_ICON: 'back-icon',
  SHARE_ICON: 'share-icon',
  RENEC_ICON: 'renec-icon',
  TIMER_ICON: 'timer-icon',
  PROGRESS_BAR_IMAGE: 'progress-bar-image',
  CHECK_CIRCLE_NO_ICON: 'check-circle-no',
  CHECK_CIRCLE_YES_ICON: 'check-circle-yes',
  WALLET_ICON: 'wallet-icon',
  LOCKED_ICON: 'locked-icon',
  RENEC_AWARDS_ICON: 'renec-awards-icon',
  CHECK_MARK_ICON: 'check-mark-icon',
  LANG_VI_ICON: 'lang-vi-icon',
  LANG_EN_ICON: 'lang-en-icon',
  TRUSTPILOT_LOGO: 'trustpilot-logo',
  TRUSTPILOT_STAR: 'trustpilot-star',
  HOME_COLOR_ICON: 'home-color-icon',
  LAST_BUYER_REWARD: 'last-buyer-reward',
  PROJECT_ICON: 'project-icon',
  TOTAL_VALUATION_ICON: 'total-valuation-icon',
  LEADERBOARD_ICON: 'leaderboard-icon',
}

export const staticImportIcon = (iconName: string) => {
  switch (iconName) {
    case IconName.RENEC_LOGO:
      return RenecLogo
    case IconName.RENEC_BLACK_LOGO:
      return RenecBlackLogo
    case IconName.PROPEASY_LOGO:
      return PropEasyLogo
    case IconName.PROPEASY_BLUE_LOGO:
      return PropEasyBlueLogo
    case IconName.PROPEASY_ICON:
      return PropEasyIcon
    case IconName.FIAT_PROP_ICON:
      return FiatPropIcon
    case IconName.MENU_PROPERTIES_ICON:
      return MenuPropertiesIcon
    case IconName.MENU_PROPERTIES_BLACK_ICON:
      return MenuPropertiesBlackIcon
    case IconName.MENU_DOCUMENT_ICON:
      return MenuDocumentIcon
    case IconName.MENU_DOCUMENT_BLACK_ICON:
      return MenuDocumentBlackIcon
    case IconName.SOCIAL_FACEBOOK:
      return SocialFacebook
    case IconName.SOCIAL_TWITTER:
      return SocialTwitter
    case IconName.SOCIAL_DISCORD:
      return SocialDiscord
    case IconName.SOCIAL_TELEGRAM:
      return SocialTelegram
    case IconName.HOME_ICON:
      return HomeIcon
    case IconName.SEARCH_ICON:
      return SearchIcon
    case IconName.AREA_ICON:
      return AreaIcon
    case IconName.INFO_ICON:
      return InfoIcon
    case IconName.OPEN_LINK_ICON:
      return OpenLinkIcon
    case IconName.CHECK_PICK_ICON:
      return CheckPickIcon
    case IconName.ARROW_RIGHT_ICON:
      return ArrowRightIcon
    case IconName.COPY_ICON:
      return CopyIcon
    case IconName.HISTORY_ICON:
      return HistoryIcon
    case IconName.VERIFY_ICON:
      return VerifyIcon
    case IconName.VERIFIED_ICON:
      return VerifiedIcon
    case IconName.ADD_CIRLCE_ICON:
      return AddCirlceIcon
    case IconName.MINUS_CIRLCE_ICON:
      return MinusCirlceIcon
    case IconName.REFERRAL_ICON:
      return ReferralIcon
    case IconName.CHECK_MARK_DONE_ICON:
      return CheckMarkDoneIcon
    case IconName.MISSION_ICON:
      return MissionIcon
    case IconName.MISSION_BOX_ICON:
      return MissionBoxIcon
    case IconName.MISSION_CERT_ICON:
      return MissionCertIcon
    case IconName.MISSION_AWARD_ICON:
      return MissionAwardIcon
    case IconName.FACEBOOK_DARK_ICON:
      return FacebookDarkIcon
    case IconName.TWITTER_DARK_ICON:
      return TwitterDarkIcon
    case IconName.TELEGRAM_DARK_ICON:
      return TelegramDarkIcon
    case IconName.COPY_LIGHT_ICON:
      return CopyLightIcon
    case IconName.PLAY_VIDEO_ICON:
      return PlayVideoIcon
    case IconName.SURVEY_COMPLETED_ICON:
      return SurveyCompletedIcon
    case IconName.GLOBAL_ICON:
      return GlobalIcon
    case IconName.GLOBAL_BLACK_ICON:
      return GlobalBlackIcon
    case IconName.SIGN_OUT_ICON:
      return SignOutIcon
    case IconName.SLIDE_NEXT:
      return SlideNextIcon
    case IconName.SLIDE_PREVIOUS:
      return SlidePreviousIcon
    case IconName.BACK_ICON:
      return BackIcon
    case IconName.SHARE_ICON:
      return ShareIcon
    case IconName.INFO_CIRCLE_ICON:
      return InfoCircleIcon
    case IconName.TIMER_ICON:
      return TimerIcon
    case IconName.PROGRESS_BAR_IMAGE:
      return ProgressBarImage
    case IconName.CHECK_CIRCLE_NO_ICON:
      return CheckCircleNoIcon
    case IconName.CHECK_CIRCLE_YES_ICON:
      return CheckCircleYesIcon
    case IconName.WALLET_ICON:
      return WalletIcon
    case IconName.LOCKED_ICON:
      return LockedIcon
    case IconName.RENEC_AWARDS_ICON:
      return RenecAwardsIcon
    case IconName.CHECK_MARK_ICON:
      return CheckMarkIcon
    case IconName.RENEC_ICON:
      return RenecIcon
    case IconName.LANG_VI_ICON:
      return LangViIcon
    case IconName.LANG_EN_ICON:
      return LangEnIcon
    case IconName.TRUSTPILOT_LOGO:
      return TrustpilotLogo
    case IconName.TRUSTPILOT_STAR:
      return TrustpilotStar
    case IconName.HOME_COLOR_ICON:
      return HomeColorIcon
    case IconName.LAST_BUYER_REWARD:
      return LastBuyerRewardIcon
    case IconName.PROJECT_ICON:
      return ProjectIcon
    case IconName.TOTAL_VALUATION_ICON:
      return TotalValuationIcon
    case IconName.LEADERBOARD_ICON:
      return LeaderboardIcon
    default:
      return PropEasyIcon
  }
}
