import { useContext } from 'react'

import { Session } from 'next-auth'

import { UserStoreContext } from '@/providers/user-store'

export const useUserStore = () => {
  const context = useContext(UserStoreContext)
  if (context === undefined) {
    throw new Error('useUserStore must be used within an UserStoreProvider')
  }

  return context
}

export function useCurrentUser(): Session {
  const context = useUserStore()
  return context.currUser
}
