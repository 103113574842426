import axios from 'axios'

import { useIntercom } from '@thanhvo102/react-use-intercom'
import { useEffect, useState } from 'react'
import {
  removeIntercomActiveClass,
  onIntercomWidgetShowed,
  onIntercomWidgetHidden,
  detectElementMutated,
} from '@/utils/helpers'
import { useCurrentUser } from '@/hooks/use-user-store'
import Cookies from 'js-cookie'

declare global {
  interface Window {
    Intercom: any
  }
}

function BootIntercom(): null {
  const [hasIntercom, setHasIntercom] = useState(false)
  const { boot, update } = useIntercom()
  const currentUser = useCurrentUser()

  useEffect(() => {
    boot()
  }, [boot])

  const updateIntercomUser = async () => {
    if (currentUser.user?.email) {
      try {
        const { data: userHash } = await axios.get('/api/intercom_user_hash')
        update({
          email: currentUser.user?.email,
          createdAt: currentUser.user?.created_at,
          userHash,
          customAttributes: {
            propeasy_user: true,
            locale: Cookies.get('locale'),
          },
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }
  }

  useEffect(() => {
    updateIntercomUser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.user])

  useEffect(() => {
    if (window.Intercom && !hasIntercom) {
      setHasIntercom(true)

      window.Intercom('onHide', onIntercomWidgetHidden)
      window.Intercom('onShow', onIntercomWidgetShowed)
    }

    detectElementMutated(document.querySelector('html'), removeIntercomActiveClass)
  }, [hasIntercom])

  return null
}

export default BootIntercom
