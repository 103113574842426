import axios from 'axios'
// import api from './'

export const fetchSettings = async () => {
  // const { data } = await api.get('/settings/propeasy')
  // return data
  // ex: { notice_messages: { a: {vi: '', en: ''} } } as any
  return {} as any
}

export const fetchGeoInfo = async () => {
  const { data } = await axios.get('/api/geo')
  return data
}

export const fetchCountryInfo = async (info: { ip: string }) => {
  const { data } = await axios.post('/api/country', info)
  return data
}
