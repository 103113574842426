import { Dialog, Stack, Typography } from '@mui/material'
import PropEasyButton from '@/components/base/PropEasyButton'
import React from 'react'
import Image from 'next/image'
import SurveyIcon from '@/assets/svgs/survey/ask-survey.svg'
import useTrans from '@/hooks/useTrans'

const AskSurveyDialog = ({ doSurvey, onClose }: { doSurvey: () => void; onClose: () => void }) => {
  const trans = useTrans()

  return (
    <Dialog
      maxWidth="xs"
      classes={{
        paper: 'p-3 rounded-lg',
      }}
      open
      onClose={onClose}
    >
      <Stack className="p-3" gap={2} alignItems="center" justifyContent="center">
        <Image src={SurveyIcon} alt="begin-icon" width={120} height={120} />
        <Typography className="text-center text-xl font-bold">{trans.survey.ask.title}</Typography>
        <Typography className="text-center text-sm">{trans.survey.ask.desc}</Typography>
        <div
          className="text-center text-sm"
          dangerouslySetInnerHTML={{
            __html: trans.survey.ask.reward,
          }}
        />
        <PropEasyButton rounded handleClick={doSurvey} text={trans.survey.ask.btn} />
      </Stack>
    </Dialog>
  )
}

export default AskSurveyDialog
