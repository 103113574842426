import React from 'react'
import type { FC, MouseEventHandler } from 'react'
import Image from 'next/image'
import { WalletReadyState } from '@solana/wallet-adapter-base'
import type { Wallet } from '@solana/wallet-adapter-react'

import { useWalletModal } from '../hooks'
import { Button } from './Button'
import { WalletIcon } from './WalletIcon'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'

interface WalletListItemProps {
  handleClick: MouseEventHandler<HTMLButtonElement>
  tabIndex?: number
  wallet: Wallet
  isMobileDevice: boolean
}

export const WalletListItem: FC<WalletListItemProps> = ({
  handleClick,
  tabIndex,
  wallet,
  isMobileDevice,
}) => {
  const { trans } = useWalletModal()
  const detectingStatus = React.useMemo(() => {
    if (!isMobileDevice) {
      switch (wallet.readyState) {
        case WalletReadyState.Installed:
          return <span className="wallets-list-item-status">{trans.detected}</span>
        case WalletReadyState.NotDetected:
          return <span className="wallets-list-item-status">{trans.not_detected}</span>
        default:
          return <></>
      }
    } else {
      return (
        <Image
          className="inline wallets-list-item-status"
          src={staticImportIcon(IconName.ARROW_RIGHT_ICON)}
          height={20}
          alt="wallet connect help icon"
        />
      )
    }
  }, [wallet, trans, isMobileDevice])

  return (
    <Button
      className="wallets-list-item rounded-xl"
      onClick={handleClick}
      beginIcon={<WalletIcon wallet={wallet} />}
      tabIndex={tabIndex}
    >
      Carax Demon
      {detectingStatus}
    </Button>
  )
}
