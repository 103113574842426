import React, { useEffect } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import { useState } from 'react'
import AskSurveyDialog from '@/components/survey/ask-survey-dialog'
import QuestionnaireDialog from '@/components/survey/questionnaire-dialog'
import { useCheckSurveyCampaign, useCheckTokenBought } from '@/hooks/use-survey'
import { submitSurvey } from '@/utils/apis/survey'
import CompleteSurveyDialog from '@/components/survey/complete-survey-dialog'
import QUESTIONNAIRE_BOUGHT from '@/components/survey/questionnaire_bought.json'
import QUESTIONNAIRE from '@/components/survey/questionnaire.json'

const Survey = () => {
  const [showDialog, setShowDialog] = useState({
    ask: false,
    questionnaire: false,
    complete: false,
  })
  const { publicKey } = useWallet()
  const walletAddress = publicKey?.toBase58() || ''
  const { data: checkSurvey } = useCheckSurveyCampaign(walletAddress)
  const { data: checkTokenBought } = useCheckTokenBought(walletAddress)
  const questionnaire = checkTokenBought?.result ? QUESTIONNAIRE_BOUGHT : QUESTIONNAIRE

  useEffect(() => {
    if (!!walletAddress && checkSurvey?.result === false) {
      setShowDialog({
        ask: true,
        questionnaire: false,
        complete: false,
      })
    }
  }, [walletAddress, checkSurvey?.result])

  const handleDoSurvey = () => {
    setShowDialog({
      ask: false,
      questionnaire: true,
      complete: false,
    })
  }

  const handleSubmit = async (data: any) => {
    const response = await submitSurvey(walletAddress, data)
    if (!response.success) return

    setShowDialog({
      ask: false,
      questionnaire: false,
      complete: true,
    })
  }

  const handleClose = async () => {
    setShowDialog({
      ask: false,
      questionnaire: false,
      complete: false,
    })
  }

  return (
    <>
      {showDialog.ask && <AskSurveyDialog doSurvey={handleDoSurvey} onClose={handleClose} />}
      {showDialog.questionnaire && (
        <QuestionnaireDialog
          questionnaire={questionnaire}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      )}
      {showDialog.complete && (
        <CompleteSurveyDialog onComplete={handleClose} onClose={handleClose} />
      )}
    </>
  )
}

export default Survey
