import React, { useMemo } from 'react'

import bs58 from 'bs58'

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react'
import { Keypair } from '@solana/web3.js'

import { E2EWalletAdapter } from '../adapters/e2e'
import { DemonWalletAdapter } from '../adapters/index'
import {
  getNetworkUrls,
  isRunningInCypress,
} from '../helpers'
import {
  WalletModalProvider,
  type WalletModalProviderProps,
} from './WalletModalProvider'

interface WalletUIProviderProps extends WalletModalProviderProps {
  isMainnet: boolean
  e2eWalletPrivKey?: string
}

export const Provider = ({
  children,
  isMainnet,
  e2eWalletPrivKey,
  ...modalProviderProps
}: WalletUIProviderProps) => {
  const wallets = useMemo(() => {
    const supportedWallets = []
    if (isRunningInCypress) {
      if (e2eWalletPrivKey) {
        supportedWallets.push(
          // @ts-ignore
          new E2EWalletAdapter({
            keypair: Keypair.fromSecretKey(bs58.decode(e2eWalletPrivKey)),
          }),
        )
      }
      // // @ts-ignore
      // supportedWallets.push(new E2EWalletAdapter())
    } else {
      supportedWallets.push(new DemonWalletAdapter())
    }

    return supportedWallets
  }, [e2eWalletPrivKey])

  const urls = useMemo(() => getNetworkUrls(isMainnet), [isMainnet])

  return (
    <ConnectionProvider
      endpoint={urls.rpc}
      config={{
        wsEndpoint: urls.wss,
        commitment: 'confirmed',
      }}
    >
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider {...modalProviderProps}>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}
