import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import {
  MAINNET_RPC_URL,
  MAINNET_WSS_URL,
  TESTNET_RPC_URL,
  TESTNET_WSS_URL,
} from '@/constants/index'

export * from './translate'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress: any
    demon: {
      sol: object
    }
  }
}

export const getNetworkUrls = (isMainnet: boolean) =>
  isMainnet
    ? { rpc: MAINNET_RPC_URL, wss: MAINNET_WSS_URL }
    : { rpc: TESTNET_RPC_URL, wss: TESTNET_WSS_URL }

export const isRunningInCypress = typeof window !== 'undefined' && window.Cypress !== undefined

export const isDemonWalletDetected = () => window.demon?.sol || window.Cypress

export const isMobileDevice = isAndroid || isIOS || isMobile

export const getDemonWalletUrl = () => {
  if (isMobileDevice) {
    return `https://demon.renec.foundation/#/connect?dapp=${window.location.href}`
  }
  return 'https://chrome.google.com/webstore/detail/demon-wallet/mdjmfdffdcmnoblignmgpommbefadffd'
}

export const handleRedirectIfDemonNotInstalled = () => {
  const url = getDemonWalletUrl()
  if (isIOS) {
    window.location.href = url
  } else {
    window.open(url)
  }
}

export const openNewWindowWithCallback = (url: string, callback?: () => void) => {
  let width = (window.innerWidth * 3) / 4
  if (width < 1028) width = 1028
  const height = (window.innerHeight * 4) / 5
  const left = window.screenX + (window.innerWidth - width) / 2
  const top = window.screenY + (window.innerHeight - height) / 2
  window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`)
  window.addEventListener('focus', () => {
    // console.log('original window has been focused.')
    callback?.()
  })
}
