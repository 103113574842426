import Cookies from 'js-cookie'
import { Session } from 'next-auth'
import { ReactElement, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { me as apiMe } from '@/utils/apis/helper'
import { useQuery } from 'react-query'
import { TOKEN_KEY } from '@/utils/apis'

import useRefetchLocaleContent from '@/hooks/useRefetchLocaleContent'

type UserStoreContextValue = {
  referralCode: string | undefined | null
  clickId: string | undefined | null
  currUser: Session
  handleSetCurrUser: (user: Session) => void
}

const initContextState: UserStoreContextValue = {
  referralCode: undefined,
  clickId: undefined,
  currUser: {
    is_wallet_address_unique: true,
    num_users_with_more_referrals: 0,
    referrals_number: 0,
    survey_completed: false,
    user: {
      id: 0,
      email: '',
      is_verified_email: false,
      prop_reward_amount: 0,
      reusd_reward_amount: 0,
      referral_code: '',
      referrer_id: 0,
      updated_at: '',
      created_at: '',
      fraud_suspected: false,
      reward_sent: false,
      wallet_address: '',
    },
  } as Session,
  handleSetCurrUser: (user: Session) => null,
}

export const UserStoreContext = createContext<UserStoreContextValue>(initContextState)

const UserStoreProvider = ({ children }: HocProps): ReactElement => {
  const [userStore, setUserStore] = useState<UserStoreContextValue>(initContextState)
  
  const { data: regInfo } = useQuery(['apiMe'], apiMe, {
    refetchOnWindowFocus: false,
    enabled: !!Cookies.get(TOKEN_KEY),
  })

  useRefetchLocaleContent()

  useEffect(() => {
    if (regInfo?.success) {
      const dataUser = regInfo?.data as Session
      setUserStore({
        ...userStore,
        currUser: {
          ...dataUser,
          user: {
            ...dataUser.user,
            prop_reward_amount: Math.round(dataUser.user.prop_reward_amount ?? 0),
            reusd_reward_amount: Math.round(dataUser.user.reusd_reward_amount ?? 0),
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regInfo])

  useEffect(() => {
    const requestQuery = new URLSearchParams(document.location.search)
    const tempStore = { referralCode: '', clickId: '' }

    // Referral
    const referral_code = requestQuery.get('referral_code')
    if (!userStore.referralCode && referral_code !== null) {
      Cookies.set('referral_code', `${referral_code}`, { expires: 7 })
      tempStore.referralCode = referral_code || ''
    }

    // Adflex
    const click_id = requestQuery.get('click_id')
    if (!userStore.clickId && click_id !== null) {
      Cookies.set('click_id', `${click_id}`, { expires: 7 })
      tempStore.clickId = click_id || ''
    }

    setUserStore({
      ...userStore,
      ...tempStore,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetCurrUser = useCallback(
    (newUser: Session) => {
      setUserStore({
        ...userStore,
        currUser: {
          ...userStore.currUser,
          ...newUser,
        },
      })
    },
    [userStore],
  )

  const value = useMemo(() => {
    return {
      ...userStore,
      handleSetCurrUser,
    }
  }, [handleSetCurrUser, userStore])

  return <UserStoreContext.Provider value={value}>{children}</UserStoreContext.Provider>
}

export default UserStoreProvider
