import { Checkbox, FormControlLabel, FormGroup, Typography, Stack, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import isArray from 'lodash/isArray'
import { useRouter } from 'next/router'
import React from 'react'
import useTrans from '@/hooks/useTrans'

const MultiChoice = ({ data }: any) => {
  const trans = useTrans()
  const { locale } = useRouter()
  const { register, control, formState, watch } = useFormContext()
  const { question, answers } = data[locale || 'vi']
  const hasError = !!formState.errors[data.id]
  const currentValue = watch(data.id)

  return (
    <Stack gap={2}>
      <Typography className={`text-sm font-bold ${hasError && 'text-red-600'}`}>
        {`${data.id.replace('_', '.')} ${question}${data.required ? '*' : ''} (${
          trans.survey.multiChoice
        })`}
      </Typography>
      <FormGroup>
        <Stack gap={1.5}>
          {answers.map((answer: string, idx: number) => (
            <Stack key={idx} gap={1.5} className="border border-slate-300 rounded-lg pl-3">
              <FormControlLabel
                control={
                  <Checkbox
                    {...register(data.id, {
                      required: true,
                    })}
                    value={answer}
                  />
                }
                label={<Typography className="text-sm">{answer}</Typography>}
              />
              {idx === answers.length - 1 && data.allow_text_answer && (
                <Controller
                  name={`${data.id}_text`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      rows={3}
                      variant="outlined"
                      className="mb-3 mr-3"
                      disabled={
                        !isArray(currentValue) ||
                        (isArray(currentValue) && !currentValue.includes(answer))
                      }
                    />
                  )}
                />
              )}
            </Stack>
          ))}
        </Stack>
      </FormGroup>
    </Stack>
  )
}

export default MultiChoice
