import { Typography, Stack, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useRouter } from 'next/router'
import React from 'react'

const InputAnswer = ({ data }: any) => {
  const { locale } = useRouter()
  const { control } = useFormContext()
  const { question, helperText } = data[locale || 'vi']

  return (
    <Stack gap={2}>
      <Typography className="text-sm font-bold">{`${data.id.replace('_', '.')} ${question}${
        data.required ? '*' : ''
      }`}</Typography>
      {helperText && <Typography className="text-sm">{helperText}</Typography>}
      <Controller
        name={data.id}
        control={control}
        render={({ field }) => <TextField {...field} variant="outlined" />}
      />
    </Stack>
  )
}

export default InputAnswer
