import { FormControlLabel, Radio, RadioGroup, Typography, Stack, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useRouter } from 'next/router'
import React from 'react'

const SingleChoice = ({ data }: any) => {
  const { locale } = useRouter()
  const { control, formState, watch } = useFormContext()
  const { question, answers } = data[locale || 'vi']
  const hasError = !!formState.errors[data.id]
  const currentValue = watch(data.id)

  return (
    <Stack gap={2}>
      <Typography className={`text-sm font-bold ${hasError && 'text-red-600'}`}>
        {`${data.id.replace('_', '.')} ${question}${data.required ? '*' : ''}`}
      </Typography>
      <Controller
        name={data.id}
        control={control}
        rules={{
          required: data.required,
        }}
        render={({ field: radioField }) => (
          <RadioGroup {...radioField}>
            <Stack gap={1.5}>
              {answers.map((answer: string, idx: number) => (
                <Stack key={idx} gap={1.5} className="border border-slate-300 rounded-lg pl-3">
                  <FormControlLabel
                    value={answer}
                    control={<Radio />}
                    label={<Typography className="text-sm">{answer}</Typography>}
                  />
                  {idx === answers.length - 1 && data.allow_text_answer && (
                    <Controller
                      name={`${data.id}_text`}
                      control={control}
                      render={({ field: textField }) => (
                        <TextField
                          {...textField}
                          multiline
                          rows={3}
                          variant="outlined"
                          className="mb-3 mr-3"
                          disabled={currentValue !== answer}
                        />
                      )}
                    />
                  )}
                </Stack>
              ))}
            </Stack>
          </RadioGroup>
        )}
      />
    </Stack>
  )
}

export default SingleChoice
