import { DATE_MONTH } from '@/constants/index'
import { timeFormatString } from '@/utils/helpers'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locale: { [key: string]: any } = {
  header: {
    vietnamese: 'Tiếng Việt',
    english: 'Tiếng Anh',
  },
  footer: {
    description:
      'Propeasy chuyển đổi bất động sản thành token trên blockchain, giúp nhà đầu tư sở hữu cổ phần tài sản với số vốn nhỏ và giao dịch linh hoạt.',
    company: 'Công Ty',
    company_about: 'Về chúng tôi',
    company_terms: 'Điều khoản dịch vụ',
    company_policy: 'Chính sách bảo mật',
    company_contact: 'Liên hệ & Hỗ trợ',
    resource: 'Tài nguyên',
    resource_document: 'Tài liệu',
    resource_news: 'Tin tức',
    resource_blog: 'Blog',
    social_follow_us: 'Theo dõi chúng tôi trên',
  },
  subscribe: {
    newsletters: 'Đăng ký bản tin',
    description: 'Cập nhật thông tin mới nhất về các dự án bất động sản đang mở bán.',
    email: 'Email',
    register: 'Đăng ký',
    success: 'Đăng ký email thành công',
    confirm_title: 'Xác thực đăng ký',
    confirm_description:
      'Chúng tôi đã gửi liên kết xác minh vào email của bạn. Vui lòng kiểm tra email và làm theo hướng dẫn để hoàn tất xác thực đăng ký email.',
    confirm_done: 'Đã hiểu',
    confirm_warning: 'Vui lòng nhập đúng định dạng email',
    confirm_error: 'Đã xảy ra lỗi, vui lòng thử lại sau',
  },
  home: {
    coming_soon: 'Sắp ra mắt',
    introduction: {
      hero_title:
        'Bất động sản 4.0<br/>Bắt đầu đầu tư <span class="text-ps-secondary">chỉ từ 10.000 đồng</span>',
      hero_description:
        'Dễ dàng sỡ hữu và giao dịch các token được bảo chứng bởi bất động sản thực do <span class="font-bold text-ps-secondary">Renec Foundation thẩm định và giám sát</span> thông qua công nghệ blockchain.',
      hero_view_properties: 'Xem tài sản',
      hero_view_introduction: 'Xem giới thiệu',
      featured_in: 'Giới thiệu bởi',
      audited_by: 'Kiểm toán bởi',
    },
    featured_listing: {
      token: 'Token',
      price: 'Giá',
      change_24h: 'Thay đổi 24h',
      asset: 'Tài sản',
      total_projects: 'Số lượng dự án',
      total_value: 'Tổng giá trị đã token hoá',
      title: 'Danh sách nổi bật',
      description: 'Khám phá các token bất động sản độc quyền cả chúng tôi cho cơ hội đầu tư của bạn',
    },
    question_answer: {
      title: 'Câu hỏi thường gặp',
      have_any_question: 'Bạn vẫn còn thắc mắc?',
      document: 'Xem tài liệu',
      details: [
        {
          question:
            'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản?',
          answer: 'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản',
        },
        {
          question:
            'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản?',
          answer: 'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản',
        },
        {
          question:
            'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản?',
          answer: 'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản',
        },
        {
          question:
            'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản?',
          answer: 'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản',
        },
      ],
    },
    information: {
      title: 'Hệ sinh thái các bất động sản thực được mã hoá',
      description:
        'Đa dạng hóa danh mục đầu tư của bạn bằng các bất động sản được mã hóa và chia nhỏ, giúp tăng tính thanh khoản, sự minh bạch và khả năng tiếp cận.',
      card_1: {
        title: 'Linh Hoạt Vốn Đầu Tư',
        description:
          'Mua token đại diện cho cổ phần của bất động sản thực chỉ từ 10.000 đồng, thay vì mua toàn bộ tài sản với chi phí cao theo cách truyền thống.',
      },
      card_2: {
        title: 'Tăng Trưởng An Toàn',
        description:
          'Bất động sản có tiềm năng tạo nguồn thu kinh doanh đều đặn và tăng giá bền vững trong dài hạn, giúp bảo vệ tài sản của nhà đầu tư khi token nắm giữ gia tăng giá trị tương ứng.',
      },
      card_3: {
        title: 'Thanh Khoản 24/7',
        description:
          'Mua bán token bất cứ lúc nào trên các sàn giao dịch với tính thanh khoản tốt, giúp nhà đầu tư tiếp cận được nguồn vốn ngay lập tức khi cần.',
      },
      card_4: {
        title: 'Nguồn Gốc Tài Sản Uy Tín',
        description:
          'Mỗi bất động sản đều đã được xác thực thông tin, thẩm định giá trị và giám sát kỹ lưỡng bởi Renec Foundation nhằm bảo vệ quyền lợi nhà đầu tư.',
      },
      card_5: {
        title: 'Hoa Hồng Giới Thiệu',
        description:
          'Không giới hạn thu nhập thụ động từ tiền hoa hồng chia cho cả người giới thiệu và người được giới thiệu.',
      },
    },
    profits: {
      title: 'Tài sản thực, lợi nhuận thực',
      description:
        'Nắm giữ token bất động sản cho phép nhà đầu tư tiếp cận bất động sản thực được mã hoá trên blockchain và kiếm lời tương ứng khi bất động sản tạo lợi nhuận - chỉ đơn giản là vậy.',
      card_1: {
        title: 'Token hoá tài sản',
        description:
          'Bất động sản thực được <span class="text-ps-secondary">xác minh và thẩm định giá trị bởi Renec Foundation</span> trước khi mã hoá lên blockchain để phát hành token bất động sản tương ứng. Mỗi token bất động sản đại diện cho cổ phần của bất động sản đó.',
      },
      card_2: {
        title: 'Tạo lợi nhuận',
        description:
          'Bất động sản được giám sát chuyên nghiệp để thực hiện đúng mục đích kinh doanh ban đầu, sản sinh ra lợi nhuận và dòng tiền ổn định đồng thời gia tăng giá trị theo thời gian.',
      },
      card_3: {
        title: 'Nắm giữ và kiếm lời',
        description:
          'Khi bất động sản phát sinh lợi nhuận kinh doanh và gia tăng giá trị, Propeasy tiến hành mua lại và đốt token bất động sản tương ứng để gia tăng giá trị cho nhà đầu tư nắm giữ token.',
      },
    },
    token_info: {
      title: 'Token trong hệ sinh thái',
      buy_now: 'Mua ngay',
      learn_more: 'Tìm hiểu thêm',
      price: 'Giá',
      market_cap: 'Vốn hoá',
      circulating: 'Cung lưu thông',
      view_all: 'Xem tất cả',
      sale_date: 'Ngày mở bán',
      prop: {
        symbol: 'PROP',
        name: 'Token gốc',
        description:
          'PROP là token gốc cho các tiện ích và quản trị nền tảng Propeasy, được dùng để tham gia vào các đợt bán riêng tư của token bất động sản, thanh toán, giao dịch, quản trị DAO, v.v.',
      },
      gast: {
        symbol: 'GAST',
        name: 'Token bất động sản',
        description:
          'GAST là token bất động sản được bảo chứng bởi bất động sản Tổ Hợp Cây Xăng và Showroom Ô Tô G6000. Mua và nắm giữ để kiếm thu nhập thụ động từ thu nhập cho thuê của tài sản thật.',
      },
      plus1: {
        symbol: 'PLUS1',
        name: 'Token bất động sản',
        description:
          'PLUS1 là token bất động sản được bảo chứng bởi bất động sản Căn Hộ Cao Cấp Landmark Plus - Vinhomes Central Park LP0001. Mua và nắm giữ để kiếm thu nhập thụ động từ thu nhập cho thuê của tài sản thật.',
      },
      aps: {
        symbol: 'APS',
        name: 'Token bất động sản',
        description:
          'APS là token bất động sản được bảo chứng bởi bất động sản Nhà Phố An Phú Sinh APS0001. Mua và nắm giữ để kiếm thu nhập thụ động từ thu nhập cho thuê của tài sản thật.',
      },
      unit: {
        million: 'Triệu',
        billion: 'Tỷ',
      },
    },
    featured_list: {
      title: 'Danh sách nổi bật',
      description:
        'Khám phá danh sách các tài sản độc quyền, mở ra cơ hội đầu tư đa dạng và tiềm năng lợi nhuận cho bạn.',
      view_all: 'Xem tất cả',
    },
    blog_list: {
      title: 'Thông báo gần đây',
      view_all: 'Xem tất cả',
    },
  },
  common: {
    partner_of: 'Đối tác của',
    powered_by: '© 2023 . Propeasy Labs',
    home: 'Trang chủ',
    search: 'Tìm kiếm...',
    login: 'Đăng nhập',
    logout: 'Đăng xuất',
    status: {
      all: 'Tất cả',
      on_sale: 'Đang mở bán',
      comming_soon: 'Sắp ra mắt',
      closed: 'Kết thúc',
      sold_out: 'Bán hết',
      burning: (startDate: string, endDate: string) =>
        `Đốt token từ ${timeFormatString(startDate, DATE_MONTH)} đến ${timeFormatString(
          endDate,
          DATE_MONTH,
        )}`,
      burnFilterLabel: 'Đang đốt token',
    },
    verifying: 'Đang xác thực tài khoản...',
    captcha: {
      anchor: 'Tôi không phải là robot',
      challenge: 'Trượt để hoàn thành bức tranh ghép',
    },
    back: 'Quay lại',
    close: 'Đóng',
    share: 'Chia sẻ',
    connect_wallet: 'Kết nối ví',
    no_data: 'Không tìm thấy kết quả',
    expand_more: 'Xem thêm',
    expand_less: 'Rút gọn',
    copied: 'Đã sao chép',
    verified: 'Đã chứng thực',
    detail: 'Chi tiết',
  },
  meta: {
    home: {
      title: 'Propeasy | Website Chính Thức',
      description:
        'Bất động sản 4.0 dựa trên công nghệ Blockchain. Bắt đầu đầu tư chỉ từ 10.000 đồng',
      thumb: '/thumb-home-propeasy-vi.png',
    },
    blogs: {
      title: 'Propeasy | Blog',
      description: 'Mọi thông tin mới nhất về bất động sản đầu tư dựa trên công nghệ blockchain',
      thumb: '/thumb-home-propeasy-vi.png',
    },
    properties: {
      title: 'Propeasy | Tài Sản Đầu Tư',
      description:
        'Danh sách các token bất động sản để bạn có thể đầu tư và sinh lời. Sự an toàn được bảo đảm bởi công nghệ blockchain',
      thumb: '/thumb-home-propeasy-vi.png',
    },
    properties_detail: (symbol: string) => ({
      title: `Propeasy | Đầu Tư Vào ${symbol}`,
      description: `Đầu tư vào token ${symbol}, tài sản tiềm năng được cung cấp và bảo đảm bởi Propeasy`,
      thumb: '/thumb-home-propeasy-vi.png',
    }),
    prelaunch: {
      title: 'Propeasy - Nền Tảng Đầu Tư Vốn Nhỏ Dễ Dàng vào Bất Động Sản trên Blockchain',
      description:
        'Tham gia sự kiện Airdrop của Propeasy trên Renec blockchain với nhiều phần quà hấp dẫn đang chờ đợi bạn!',
      thumb: '/thumb-share-propeasy-vi.png',
    },
    terms_of_service: {
      title: 'Propeasy - Điều khoản dịch vụ',
      description:
        'Các Điều khoản Dịch vụ chi phối việc bạn truy cập và sử dụng Trang web và Dịch vụ của Propeasy',
      thumb: '/thumb-home-propeasy-vi.png',
    },
    privacy_policy: {
      title: 'Propeasy - Chính sách bảo mật',
      description:
        'Chính sách Bảo mật này chi phối việc bạn truy cập và sử dụng Trang web Propeasy',
      thumb: '/thumb-home-propeasy-vi.png',
    },
  },
  main_menu: {
    properties: 'Bất động sản',
    document: 'Tài liệu',
    blog: 'Blog',
  },
  error: {
    go_back_home: 'Về trang chủ',
    error_404: '404 - Không tìm được trang',
    error_message: 'Đã xảy ra lỗi, vui lòng thử lại sau',
    email_verified: 'Email đã được đăng ký, vui lòng đăng nhập hoặc thử lại với email khác',
    email_not_verified:
      'Email của bạn chưa được đăng ký, vui lòng đăng ký và xác thực email thành công để đủ tham gia chương trình Airdrop độc quyền của chúng tôi',
    error_captcha_required: 'Yêu cầu xác thực hình ảnh',
    fetch_burn_proof_error: 'Không thể tải dữ liệu đốt token',
  },
  properties: {
    apy: 'Est. APY',
    business_profit: 'Lợi nhuận kinh doanh:',
    asset_profit: 'Lợi nhuận tăng giá tài sản:',
    detail: 'Xem chi tiết',
    est_open_for_sale: 'Ngày dự kiến mở bán',
    open_for_sale: 'Ngày mở bán',
    buy_now: 'Mua ngay',
    buy_now_subtitle: (tokenNumber: string, tokenPrice: number) =>
      `Có sẵn: ${tokenNumber} token${tokenPrice ? ` với giá $${tokenPrice}` : ''}`,
  },
  properties_detail: {
    share: 'Chia sẻ mã giới thiệu',
    introduction: 'Giới thiệu bất động sản',
    location: 'Địa điểm:',
    kind: 'Loại tài sản:',
    size: 'Diện tích:',
    price: 'Định giá:',
    apy: 'Lợi nhuận năm',
    utility: 'Tiện ích độc đáo',
    legal: 'Tài liệu pháp lý',
    authenticated: 'Renec foundation đã chứng thực',
    renec_authenticated: (total: number) =>
      `${total} tài liệu đã được <br/> <strong>RENEC Foundation</strong> chứng thực`,
    security_token: 'Token bất động sản',
    token: 'Token',
    total_supply: 'Tổng cung',
    token_address: 'Địa chỉ token',
    open_for_sale: 'Mở bán',
    more_liquidity: 'Thêm thanh khoản',
    burnt_amount: 'Đã đốt',
    value_growth: 'Cơ chế tăng trưởng giá trị',
    value_growth_one: 'Đốt token định kì sử dụng lợi nhuận của tài sản',
    value_growth_one_notice:
      'Propeasy công bố tiến độ kinh doanh hằng tháng từ bất động sản cho những người nắm giữ token và cam kết 100% lợi nhuận kinh doanh được sử dụng để thực hiện đốt token.',
    value_growth_two: 'Mua lại token khi giải chấp tài sản với giá tốt',
    value_growth_two_notice:
      'Khi bất động sản gia tăng giá trị đủ tốt trong tương lai, Propeasy thực hiện giải chấp tài sản với giá tốt cho những người nắm giữ token bằng việc cam kết mua lại toàn bộ token với định giá hấp dẫn tại thời điểm giải chấp.',
    ownership_benefits: 'Tiện ích sở hữu',
    ownership_benefits_one:
      'Tăng trưởng giá trị an toàn trong dài hạn theo cơ chế tăng trưởng giá trị độc đáo của bất động sản',
    ownership_benefits_two: (token: string, articleUrl?: string) =>
      `Thưởng PROP với mỗi giao dịch mua ${token} từ chương trình ${
        articleUrl
          ? `<a class="text-ps-secondary underline font-bold" target="_blank" href="${articleUrl}">Hoa Hồng Giới Thiệu</a>`
          : 'Hoa Hồng Giới Thiệu'
      }`,
    ownership_benefits_three: (swapUrl?: string) =>
      `Giao dịch linh hoạt với thanh khoản 24/7 trên <a class="text-ps-secondary underline font-bold" target="_blank" href="${
        swapUrl ? swapUrl : 'https://nemo.renec.org/'
      }">NemoSwap</a>`,
    ownership_benefits_four:
      'Thế chấp token dễ dàng để vay lại tiền mặt trên <a class="text-ps-secondary underline font-bold" href="https://lend.renec.org/vi">RENEC Lend</a>',
    sale_info: {
      private_sale: 'Bán riêng tư',
      public_sale: 'Bán công khai',
      price: 'Giá',
      promotion: (discount: number) => `Ưu đãi ${discount.toLocaleString()}%`,
      qty_for_sale: 'Số Lượng Mở Bán',
      minimum_purchase: 'Mua Tối Thiểu',
      lock_time: 'Thời Gian Khóa',
      opening_date: 'Ngày Mở Bán',
      unlock_duration: (weeks: number) => (weeks > 0 ? `${weeks.toFixed()} tuần` : 'Không có'),
      commission_amount: 'Hoa hồng giới thiệu',
      commission_notice: (amount: string) =>
        `Thưởng hoa hồng lên đến ${amount} PROP cho cả người mua và người giới thiệu`,
      commission_tooltip:
        'Phần thưởng được chia đều cho cả người giới thiệu và tuyến dưới khi tuyến dưới thực hiện mua thành công',
      sold: (sold: number, total: number, token: string) =>
        `Đã bán: <span class="font-bold text-[#148F00]">${sold.toLocaleString()}</span> / <span class="text-[#8F9397]">${total.toLocaleString()} ${token}</span>`,
      conditions: 'Điều kiện tham gia',
      condition_one: (propReq: number, prop: number) =>
        `Có ít nhất ${propReq} PROP trong ví ${
          prop === 0
            ? '<a class="text-ps-secondary underline" target="_blank" href="https://nemo.renec.org/vi/reUSD_PROP">(Mua PROP)</a>'
            : ''
        }`,
      condition_two: (address?: string) =>
        `Địa chỉ ví đã KYC thành công ${
          address
            ? `<br/><a class="text-ps-secondary font-bold underline" href="https://remitano.com/btc/au/verify-ekyc?fromDemonWallet=${address}">KYC ngay</a>`
            : ''
        }`,
      condition_none: 'Không có yêu cầu. Mọi người đều có thể tham gia.',
      qualified: 'Bạn đủ điều kiện',
      unqualified: 'Bạn chưa đủ điều kiện',
      lock_duration_notice: (date: string) => `Khoá kết thúc vào: <strong>${date}</strong>`,
      error: (code: string) => {
        switch (code) {
          case '0x1770':
            return 'The start time must be before the end time'
          case '0x1771':
            return 'The total supply amount of sales must be smaller than or equal to supply of token'
          case '0x1772':
            return 'Không nằm trong thời gian mở bán'
          case '0x1773':
            return 'Vượt quá số lượng mở bán'
          case '0x1774':
            return 'Must have platform token account in private sale'
          case '0x1775':
            return 'Thấp hơn số tiền tối thiểu cho phép'
          case '0x1776':
            return 'Must have enough platform token in private sale'
          case '0x1777':
            return 'Mismatch token account and owner'
          case '0x1778':
            return 'There are no amount to claim'
          case '0x1779':
            return 'Not enough balance to transfer'
          case 'blockhash_not_found':
            return 'Blockhash not found'
          case 'transaction_timeout':
            return 'Thời gian giao dịch đã vượt quá giới hạn thời gian cho phép'
          case 'no_record_credit':
            return 'Bạn cần có ít nhất 0.00001 RENEC trong ví để thực hiện thành công giao dịch'
          default:
            return ''
        }
      },
    },
    purchase: {
      last_buyer_dialog: {
        title: 'Chúc mừng bạn nhận được',
        reward: (amount: number) => `${amount} USDT`,
        content: (amount: number, token: string) =>
          `Bạn là người mua cuối cùng của token ${token.toUpperCase()} trong đợt mở bán này và sẽ nhận được phần thưởng ${amount} USDT.`+
          ' Số tiền sẽ được gửi đến tài khoản của bạn trong vòng 5 ngày làm việc. Cảm ơn bạn đã tham gia và đầu tư vào dự án của chúng tôi!',
        ok: 'ok',
      },
      enter_amount: 'Nhập số tiền bạn muốn mua:',
      warning_amount: 'Vui lòng nhập số tiền',
      max_amount: 'Tối đa',
      receive: 'Nhận về:',
      locked: 'Còn khóa:',
      commission: 'Hoa hồng của bạn:',
      cal_token: (amount: number, token_symbol: string, color: string, prefix?: string) =>
        `${
          !!!amount
            ? '--'
            : `<span class="text-[${color}]">${prefix || ''}${amount.toLocaleString()}</span>`
        } ${token_symbol}`,
      balance: 'Số dư:',
      buy_now: 'Mua ngay',
      summary_transaction: 'Tóm tắt giao dịch',
      property: 'Tài sản:',
      token: 'Token:',
      price: 'Giá:',
      sell_type: 'Đợt mở bán:',
      amount: 'Số lượng:',
      bonus: 'Thưởng thêm:',
      confirm_sell: 'Xác nhận mua',
      transaction_policy:
        'Bằng việc tiếp tục, bạn đồng ý tuân thủ <a href="/terms-of-service" target="_blank" class="text-ps-secondary">Điều khoản dịch vụ </a> của chúng tôi và tất cả các quy định pháp luật liên quan.',
      sent_transaction: 'Đã Gửi Giao Dịch',
      sent_transaction_description: (token: string) =>
        `Sau khi giao dịch của bạn trên RENEC blockchain được xác thực thành công, số lượng mã ${token} đã mua sẽ xuất hiện trong địa chỉ ví của bạn.`,
      share_referral: 'Chia sẻ mã giới thiệu',
      unlimited_commission: 'Thu nhập hoa hồng không giới hạn',
      transaction_history: 'Xem giao dịch trên Explorer',
      sent_transaction_failed: 'Giao dịch thất bại',
      sent_transaction_failed_description: 'Gửi giao dịch thất bại. Vui lòng thử lại.',
      retry: 'Thử lại',
      error: {
        zero_amount: 'Vui lòng nhập số tiền',
        minimumPurchase: 'Số tiền phải lớn hơn hoặc bằng mua tối thiểu',
        maximumPurchase: 'Vượt quá số lượng mua cho phép',
        insufficient_funds: 'Không đủ số dư',
      },
      deposit_usdt: 'Xem cách nạp USDT',
      deposit_usdt_url: 'https://renec.org/blog/huong-dan-nap-usdt-vao-vi-carax',
    },
    asset_type: {
      residential_land: 'Đất thổ cư',
      commercial_land: 'Đất thương mại dịch vụ',
      service_apartment: 'Căn hộ dịch vụ',
      townhouse_for_rent: 'Nhà cho thuê',
      forest_land_for_commercials: 'Đất rừng sản xuất',
    },
    area_type: {
      meter: 'm²',
    },
    valuation_type: {
      million: 'triệu',
      billion: 'tỷ',
    },
    utilities: {
      buy: {
        title: (tokenSymbol: string) => `Mua ${tokenSymbol.toUpperCase()}`,
        desc: (tokenSymbol: string) =>
          `Bạn chưa có ${tokenSymbol.toUpperCase()}? Hãy bắt đầu giao dịch bất cứ lúc nào với thanh khoản linh hoạt 24/7.`,
        btn: 'Mua ngay',
      },
      deposit: {
        title: (tokenSymbol: string) => `Ký gửi ${tokenSymbol.toUpperCase()}`,
        desc: (tokenSymbol: string) =>
          `Gia tăng lợi nhuận của bạn bằng cách ký gửi ${tokenSymbol.toUpperCase()} để nhận lãi suất hấp dẫn hoặc mượn tiền mặt dễ dàng, nhanh chóng.`,
        btn: 'Ký gửi ngay',
      },
    },
    burn_proof: {
      title: 'Đốt token',
      time: 'Thời gian',
      hash: 'Hash',
      amount: 'Số lượng đốt',
    },
    buyer_leaderboard: {
      title: 'Top người dùng mua nhiều',
      description: '10 người đứng đầu với khối lượng giao dịch nhiều nhất sẽ nhận được',
      reward: (amount: number) => `${amount} USDT`,
      rank: 'Thứ hạng',
      buy_amount: 'Khối lượng mua (USDT)',
      you: 'Bạn',
      reward_dialog: {
        title: 'Chúc mừng bạn nhận được',
        content: (amount: number, token: string) =>
          `Bạn nằm trong top 10 người dùng có khối lượng mua token ${token.toUpperCase()} cao nhất trong đợt mở bán này và sẽ nhận được phần thưởng ${amount} USDT.`+
          ' Số tiền sẽ được gửi đến tài khoản của bạn trong vòng 5 ngày làm việc. Cảm ơn bạn đã tham gia và đầu tư vào dự án của chúng tôi!',
        ok: 'ok',
      },
    },
  },
  launch: {
    home: {
      subject:
        'Bất động sản 4.0<br/>Bắt đầu đầu tư <span class="text-ps-secondary font-bold">chỉ từ 10,000 đồng.</span>',
      description:
        'Chúng tôi mang đến giải pháp công nghệ blockchain cho phép bạn dễ dàng sỡ hữu và giao dịch các token được bảo chứng bởi bất động sản thực do <span class="text-ps-secondary font-bold">Renec Foundation thẩm định và giám sát.</span>',
      sub_description:
        'Đăng ký để nhận thông tin mới nhất về <span class="text-ps-secondary font-bold">phiên bản chính thức của Propeasy sắp ra mắt</span> và khám phá cơ hội sử dụng lượng token PROP bạn đã tích lũy!',
      coming_soon: 'Sắp ra mắt',
      play_video: 'Xem video',
      video_url: 'https://www.youtube.com/embed/8P8ASC4-mBA?autoplay=1',
      video_title: 'Mô hình hoạt động của Propeasy: Nền tảng đầu tư Bất Động Sản 4.0 mới',
      information: {
        card_1: {
          title: 'Linh Hoạt Vốn Đầu Tư',
          description:
            'Mua token đại diện cho cổ phần của bất động sản thực chỉ từ 10.000 đồng, thay vì mua toàn bộ tài sản với chi phí cao theo cách truyền thống.',
        },
        card_2: {
          title: 'Tăng Trưởng An Toàn',
          description:
            'Bất động sản có tiềm năng tạo nguồn thu kinh doanh đều đặn và tăng giá bền vững trong dài hạn, giúp bảo vệ tài sản của nhà đầu tư khi token nắm giữ gia tăng giá trị tương ứng.',
        },
        card_3: {
          title: 'Thanh Khoản 24/7',
          description:
            'Mua bán token bất cứ lúc nào trên các sàn giao dịch với tính thanh khoản tốt, giúp nhà đầu tư tiếp cận được nguồn vốn ngay lập tức khi cần.',
        },
        card_4: {
          title: 'Nguồn Gốc Tài Sản Uy Tín',
          description:
            'Mỗi bất động sản đều đã được xác thực thông tin, thẩm định giá trị và giám sát kỹ lưỡng bởi Renec Foundation nhằm bảo vệ quyền lợi nhà đầu tư.',
        },
        card_5: {
          title: 'Hoa Hồng Trọn Đời',
          description:
            'Không giới hạn thu nhập thụ động từ tiền hoa hồng lên đến 40% phí giao dịch cho cả người giới thiệu và người được giới thiệu.',
        },
        card_6: {
          title: 'Thao Tác Đơn Giản',
          description:
            'Thanh toán bằng crypto và sở hữu token ngay lập tức trong vòng chưa đến 5 phút chỉ với vài cú click chuột trên giao diện đơn giản, an toàn và tiện lợi. ',
        },
      },
    },
    register: {
      description:
        'Đăng ký bên dưới để nhận thông báo sớm khi Propeasy ra mắt và tham gia vào chương trình Airdrop với nhiều phần quà hấp dẫn.',
      email: 'Nhập vào email của bạn...',
      register: 'Đăng ký',
      total_register: (total: number) =>
        `<span class="text-ps-secondary font-bold">${total.toLocaleString(
          'vi',
        )}</span> người dùng đã tham gia airdrop`,
      confirm_title: 'Xác thực đăng ký',
      confirm_description:
        'Chúng tôi đã gửi liên kết xác minh vào email của bạn. Vui lòng kiểm tra hộp thư và làm theo hướng dẫn để hoàn tất xác thực.',
      confirm_done: 'Đã hiểu',
    },
    login: {
      title: 'Nhập email mà bạn đã đăng ký',
      confirm_title: 'Xác thực đăng nhập',
      confirm_description:
        'Chúng tôi đã gửi liên kết xác minh vào email của bạn. Vui lòng kiểm tra hộp thư và làm theo hướng dẫn để hoàn tất đăng nhập.',
      confirm_done: 'Đã hiểu',
      account_not_yet: 'Chưa có tài khoản?',
    },
    countdown: {
      title: 'Kết thúc vào',
      date_open: 'Ngày mở bán',
      day: 'Ngày',
      hour: 'Giờ',
      minute: 'Phút',
      second: 'Giây',
    },
    airdrop: {
      subject: 'Chương trình Airdrop token PROP dành cho cộng đồng PROPEASY',
      description: 'Hoàn thành các nhiệm vụ bên dưới để nhận thật nhiều PROP và USDT về ví nhé!',
      more: 'Xem lợi ích của token PROP',
      total_register: (total: number) =>
        `<span class="text-ps-dark-blue font-bold">${total.toLocaleString(
          'vi',
        )}</span> người dùng đã tham gia airdrop`,
      about: {
        title: 'Thông tin về PROP',
        confirm_done: 'Đã hiểu',
        header:
          'PROP là token chính của nền tảng Propeasy. Khi nắm giữ PROP, bạn sẽ được hưởng các lợi ích sau:',
        details: [
          'Tham Gia vào các đợt Bán Riêng Tư với giá ưu đãi mỗi khi token mới được mở bán',
          'Bỏ phiếu cho các đề xuất phát triển và hoạt động quan trọng của nền tảng',
          'Giao Dịch PROP trên các sàn giao dịch tiền mã hóa',
        ],
        footer: 'Những chi tiết khác về PROP sẽ được công bố khi Propeasy chính thức ra mắt.',
      },
      join_title: 'Đăng ký thành công',
      join_description:
        'Cảm ơn bạn đã gia nhập <strong>cộng đồng Propeasy</strong>, nơi chúng ta cùng nhau xây dựng tương lai của bất động sản 4.0.<br /><br />Để tri ân sự ủng hộ của bạn, Đừng bỏ lỡ cơ hội tham gia vào <strong>đợt Airdrop đầu tiên</strong> để nhận những phần quà đặc biệt dành riêng cho cộng đồng Propeasy.',
      join_confirm: 'Tham gia Airdrop',
      congratulate: 'Chúc mừng, bạn nhận được',
      amount_received: (value: number) => `<strong>${value}</strong> PROP`,
      survey_confirm: 'Ok',
      ended: 'Đã kết thúc',
      warning_title: 'Cập nhật địa chỉ ví nhận thưởng',
      warning_description:
        'Địa chỉ ví mà bạn kết nối trước đây hiện đang kết nối với nhiều tài khoản email khác. Để đảm bảo tính công bằng và minh bạch cho tất cả người dùng, <strong>mỗi tài khoản email chỉ được kết nối với một địa chỉ ví duy nhất.</strong><br /><br />Vui lòng cập nhật lại địa chỉ ví nhận thưởng để chúng tôi tiến hành gửi token thưởng đến bạn trong vòng 48 tiếng sau khi chương trình kết thúc.',
      warning_suspected_title: 'Phát hiện hành vi bất thường',
      warning_suspected_description:
        'Chúng tôi phát hiện hành vi bất thường từ tài khoản của bạn. Điều này có thể do tài khoản của bạn hoặc người được bạn giới thiệu có hành vi trục lợi không công bằng từ chương trình giới thiệu bạn bè của Propeasy. Sau khi cân nhắc kĩ lưỡng, chúng tôi rất tiếc khi phải quyết định rằng phần thưởng sẽ không được phân phối đến tài khoản của bạn.',
      warning_confirm: 'Đã hiểu',
    },
    survey: {
      login: 'Đăng nhập',
      login_to_continue: 'Đăng nhập để thực hiện',
      mission_one: {
        title: 'Nhiệm vụ 1',
        topic: 'Khảo sát nhu cầu',
        description:
          'Tham gia trả lời dưới đây để giúp chúng tôi hiểu nhu cầu của bạn và phục vụ tốt hơn.',
        notice: 'Nhận ngẫu nhiên đến <strong>10 PROP</strong> vào ví sau khi hoàn tất khảo sát.',
        question_one:
          '<strong>Câu hỏi 1 (*):</strong> Bạn mong muốn lợi nhuận năm tối thiểu là bao nhiêu khi đầu tư vào token được bảo chứng bởi bất động sản thực (như đất lâm nghiệp, đất thương mại dịch vụ,...) do Renec Foundation thẩm định và giám sát?',
        question_two:
          '<strong>Câu hỏi 2 (*):</strong> Nếu bạn có thể đầu tư vào các token với lợi nhuận tối thiểu ở câu hỏi 1, bạn sẵn sàng đầu tư với số vốn bao nhiêu tiền cho mỗi đợt mở bán?',
        question_three:
          '<strong>Câu hỏi 3:</strong> Bạn có mong muốn gì khi tham gia vào đầu tư token được bảo chứng bởi bất động sản?',
        question_two_marks: {
          vietnam: [
            {
              value: 0,
              label: '0 đồng',
            },
            {
              value: 1000,
              label: '1 tỷ đồng',
            },
          ],
          nigeria: [
            {
              value: 0,
              label: 'NGN 0K',
            },
            {
              value: 30000,
              label: 'NGN 30 triệu',
            },
          ],
          default: [
            {
              value: 0,
              label: 'USD 0K',
            },
            {
              value: 1000,
              label: 'USD 1 triệu',
            },
          ],
        },
        question_two_value_format: (country: string, value: number) => {
          switch (country) {
            case 'nigeria':
              return value < 1000
                ? `${value.toLocaleString()}K`
                : `${(value / 1000).toLocaleString()}M`
            case 'vietnam':
              return value < 1000
                ? `${value.toLocaleString()}${value === 0 ? ' đồng' : ' triệu'}`
                : `${(value / 1000).toLocaleString()} tỷ`
            default:
              return value < 1000
                ? `${value.toLocaleString()}K`
                : `${(value / 1000).toLocaleString()}M`
          }
        },
        potential_profits: (country: string, value: number) => {
          let label = ''
          switch (country) {
            case 'nigeria':
              label =
                'NGN ' +
                (value < 1000
                  ? `${value.toLocaleString()}K`
                  : `${(value / 1000).toLocaleString()}M`)
              break
            case 'vietnam':
              label =
                value < 1000
                  ? `${value.toLocaleString()} triệu đồng`
                  : `${(value / 1000).toLocaleString()} tỷ đồng`
              break
            default:
              label =
                'USD ' +
                (value < 1000
                  ? `${value.toLocaleString()}K`
                  : `${(value / 1000).toLocaleString()}M`)
              break
          }
          return `Tổng lợi nhuận hằng năm: <strong class="text-lg text-[#3EA40E]">${label}</strong>`
        },
        question_three_placeholder: 'Nhập câu trả lời...',
        submit: 'Hoàn tất',
        error_message: 'Đã xảy ra lỗi, vui lòng thử lại sau',
      },
      mission_two: {
        title: 'Nhiệm vụ 2',
        topic: 'Chia sẻ với bạn bè',
        description:
          'Giới thiệu bạn bè đăng ký email tham gia Airdrop. Càng đông bạn bè đăng ký, càng nhiều quà cho tất cả mọi người!',
        notice_one:
          'Nhận ngẫu nhiên <strong class="text-ps-secondary">đến 5 PROP</strong> vào ví cho mỗi lượt bạn bè được giới thiệu đăng ký thành công.',
        notice_two:
          'Nhận <strong class="text-ps-secondary">10 USDT (reUSD)</strong> vào ví khi bạn nằm trong top 100 người dùng giới thiệu bạn bè nhiều nhất.',
        notice_three:
          'Mỗi người bạn được giới thiệu thành công cũng sẽ nhận được <strong class="text-ps-secondary">1 PROP</strong> vào ví của họ.',
        share: {
          title: 'Chia sẻ mã giới thiệu tới bạn bè',
          other: 'Hoặc chia sẻ qua',
          referral_success: (total: number) =>
            `<strong>Giới thiệu thành công: ${total.toLocaleString()}</strong> Bạn`,
          referral_other: (total: number) =>
            `<strong>${total.toLocaleString()} người dùng khác</strong> đang giới thiệu thành công nhiều hơn bạn`,
          copied: 'Đã sao chép',
        },
      },
      finished: 'Đã hoàn thành khảo sát',
    },
    profile: {
      welcome: 'Chào',
      award: 'Phần thưởng của bạn:',
      amount_prop: (value: number) => `<strong>${value}</strong> PROP`,
      amount_reusd: (value: number) => `<strong>${value}</strong> USDT (reUSD)`,
      connect_to_received: 'Kết nối ví nhận thưởng',
      notice:
        'Phần thưởng sẽ được gửi vào ví của bạn trước <strong>23h59 ngày 30/11/2023</strong>.',
      notice_award:
        'Không hợp lệ nhận thưởng do bạn đăng ký tài khoản sau ngày <strong>15/11/2023</strong> hoặc kết nối ví có KYC sau ngày <strong>26/11/2023</strong>.',
      notice_kyc: 'Bạn chưa xác thực địa chỉ ví để nhận thưởng.',
      continue_kyc: 'Xác thực ngay',
      get_award: 'Nhận thưởng',
      awarded: 'Đã nhận thưởng',
      waiting_for_reward: 'Đang chờ nhận thưởng',
      kyc_now: 'KYC ngay',
      kyc_help:
        'Địa chỉ ví của bạn chưa được KYC để nhận thưởng. <a class="text-ps-secondary underline" href="https://renec.org/blog/ra-mat-renecid" target="_blank">Xem hướng dẫn</a> để hoàn thành KYC và nhận thưởng.',
      logout: 'Đăng xuất',
    },
  },
  transactionHistory: {
    title: 'Lịch Sử Giao Dịch',
    all: 'Tất cả',
    private: 'Bán Riêng Tư',
    public: 'Bán Công Khai',
    claim: 'Mở khóa',
    property: 'Bất động sản',
    token: 'Token',
    price: 'Giá',
    txType: 'Giao dịch',
    amount: 'Số lượng',
    time: 'Thời gian',
    hash: 'Hash',
    statuses: {
      success: 'Thành công',
      failed: 'Thất bại',
      pending: 'Đang xác nhận',
    },
  },
  purchaseHistory: {
    not_purchase: 'Bạn không tham gia vào đợt mở bán này',
    title: 'Lịch sử mua',
    history: 'Xem tất cả',
    paid: 'Bạn đã mua',
    refundedAmount: 'Đã trả về ví',
    lockedAmount: 'Còn khóa',
    lockedAmountNotice: (date: string) => `Khóa kết thúc vào</br><b>${date}</b>`,
    nextUnlockedDate: 'Mở khoá kế tiếp vào',
    canUnlockAmount: 'Có thể mở khóa về ví',
    canUnlock: 'Mở khoá về ví',
    claim_success: (tokenSymbol: string) =>
      `Sau khi giao dịch của bạn trên Renec blockchain được xác thực thành công, số lượng token ${
        tokenSymbol ? tokenSymbol.toUpperCase() + ' ' : ''
      }đã mở khoá sẽ xuất hiện trong địa chỉ ví của bạn.`,
    claim_failure: 'Gửi giao dịch thất bại, vui lòng thử lại',
    close: 'Đóng',
  },
  blog: {
    categories: {
      all: 'Tất cả',
      announcement: 'Thông báo',
      event: 'Sự kiện',
    },
  },
  blog_detail: {
    published_at: 'Xuất bản lúc',
  },
  survey: {
    multiChoice: 'chọn nhiều',
    submit: 'Hoàn tất',
    personalInfo: {
      title: '1. Thông tin cá nhân',
      desc: 'Chúng tôi cam kết bảo mật thông tin của bạn',
    },
    surveyInfo: {
      title: '2. Khảo sát đợt Mở bán token bất động sản GAST',
      desc: 'Hãy giúp chúng tôi đánh giá chất lượng đợt mở bán token bất động sản GAST gần đây',
    },
    ask: {
      title: 'Khảo sát ý kiến người dùng về Đợt mở bán Token bất động sản GAST',
      desc: 'Ý kiến và thông tin bạn chia sẻ sẽ giúp chúng tôi đánh giá chất lượng đợt mở bán token bất động sản GAST gần đây và cung cấp cơ sở để cải thiện tốt hơn cho các dự án mở bán sắp tới. Chúng tôi cam kết bảo mật thông tin của bạn và sẽ duyệt xét mọi đóng góp một cách cẩn thận.',
      reward:
        'Những đóng góp hữu ích sẽ nhận được quà tặng tri ân từ <strong>100 đến 500 PROP</strong>. Propeasy cảm ơn bạn đã góp phần vào sự phát triển của nền tảng.',
      btn: 'Làm khảo sát',
    },
    complete: {
      title: 'Hoàn tất khảo sát',
      desc_1: 'Khảo sát đã hoàn thành. Chúng tôi chân thành cảm ơn sự đóng góp của bạn.',
      desc_2:
        'Propeasy sẽ xem xét kết quả và thông báo cho những người dùng được nhận thưởng trong vòng 4-5 ngày làm việc kể từ khi khảo sát kết thúc.',
      btn: 'OK',
    },
  },
  trustpilot: {
    rateNow: 'Đánh giá ngay',
    overview: (totalStar: number, totalReview: number) =>
      `${totalStar}/5 dựa trên ${totalReview} đánh giá`,
  },
}

export default locale
