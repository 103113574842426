import { ReactElement, createContext, useCallback, useEffect } from 'react'
import { useCountry } from '@/root/src/hooks/use-country'
import useLang from '@/hooks/useLang'
import Cookies from 'js-cookie'

interface LanguageContextValues {
  handleChangeLanguage: (lang: string) => void
  lang: string
}

export const LanguageContext = createContext({} as LanguageContextValues)

function Provider({ children }: HocProps): ReactElement {
  const [lang, setLang] = useLang()
  const [country] = useCountry()

  const handleChangeLanguage = useCallback(
    (value: string) => {
      localStorage.setItem('lang', value)
      Cookies.set('locale', value)
      setLang(value)
    },
    [setLang],
  )

  useEffect(() => {
    if (!localStorage.getItem('lang') && country?.lang) {
      handleChangeLanguage(country?.lang)
    }
  }, [handleChangeLanguage, country?.lang])

  const contextValues = {
    handleChangeLanguage,
    lang,
  }

  return <LanguageContext.Provider value={contextValues}>{children}</LanguageContext.Provider>
}

export default Provider
