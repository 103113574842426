import {
  useMutation,
  useQuery,
} from 'react-query'

import { fetchKycVerifications } from '@/utils/apis/kyc'
import { updateReferralAddress } from '@/utils/apis/referral'

export const useFetchKycVerifications = (address?: string) => {
  return useQuery(
    ['fetchKycVerifications', address],
    async () => {
      if (address) return await fetchKycVerifications(address)
      return null
    },
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useUpdateReferralAddress = () => {
  return useMutation(async (address?: string) => {
    if (address) return await updateReferralAddress(address)
    return null
  })
}
