import type {
  FC,
  MouseEvent,
} from 'react'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

import Image from 'next/image'

import WalletConnectImage from '@/assets/wallet-connect-help.png'
import {
  IconName,
  staticImportIcon,
} from '@/root/src/utils/static-import-icon'
import Dialog from '@mui/material/Dialog'
import Drawer from '@mui/material/Drawer'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import type { WalletName } from '@solana/wallet-adapter-base'
import {
  useWallet,
  Wallet,
} from '@solana/wallet-adapter-react'

import {
  DemonWalletName,
  E2EWalletName,
} from '../adapters'
import {
  getDemonWalletUrl,
  handleRedirectIfDemonNotInstalled,
  isDemonWalletDetected,
  isMobileDevice,
  openNewWindowWithCallback,
} from '../helpers'
import { useWalletModal } from '../hooks'
import WalletAccept from './WalletAccept'
import { WalletListItem } from './WalletListItem'

export interface WalletModalProps {
  className?: string
  container?: string
}

const useHandleTabKey = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { setVisible } = useWalletModal()
  const [fadeIn, setFadeIn] = useState(false)
  const [portal, setPortal] = useState<Element | null>(null)

  const handleTabKey = useCallback(
    (event: KeyboardEvent) => {
      const node = ref.current
      if (!node) return

      // here we query all focusable elements
      const focusableElements = node.querySelectorAll('button')
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const firstElement = focusableElements[0]!
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const lastElement = focusableElements[focusableElements.length - 1]!

      if (event.shiftKey) {
        // if going backward by pressing tab and firstElement is active, shift focus to last focusable element
        if (document.activeElement === firstElement) {
          lastElement.focus()
          event.preventDefault()
        }
      } else {
        // if going forward by pressing tab and lastElement is active, shift focus to first focusable element
        if (document.activeElement === lastElement) {
          firstElement.focus()
          event.preventDefault()
        }
      }
    },
    [ref],
  )

  const hideModal = useCallback(() => {
    setFadeIn(false)
    setTimeout(() => setVisible(false), 150)
  }, [setVisible])

  useLayoutEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        hideModal()
      } else if (event.key === 'Tab') {
        handleTabKey(event)
      }
    }

    // Get original overflow
    const { overflow } = window.getComputedStyle(document.body)
    // Hack to enable fade in animation after mount
    setTimeout(() => setFadeIn(true), 0)
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden'
    // Listen for keydown events
    window.addEventListener('keydown', handleKeyDown, false)

    return () => {
      // Re-enable scrolling when component unmounts
      document.body.style.overflow = overflow
      window.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [hideModal, handleTabKey])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => setPortal(document.querySelector('body')))

  const handleClose = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      hideModal()
    },
    [hideModal],
  )

  return { ref, handleTabKey, fadeIn, portal, handleClose, hideModal }
}

const DesktopExtensionInstallingConfirmationDialog = (props: {
  openNotice: boolean
  handleCloseNotice: () => void
  handleConfirmNotice: () => void
}) => {
  const { openNotice, handleCloseNotice, handleConfirmNotice } = props

  return (
    <Dialog
      open={openNotice}
      onClose={handleCloseNotice}
      className="z-[1560]"
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: (theme) => theme.palette.primary.main,
          borderRadius: '1rem',
        },
      }}
    >
      <WalletAccept
        handleCloseNotice={handleCloseNotice}
        handleConfirmNotice={handleConfirmNotice}
      />
    </Dialog>
  )
}

const MobileAppInstallingConfirmation = (props: {
  openNotice: boolean
  handleCloseNotice: () => void
  hideModal: () => void
  handleClose: (event: MouseEvent) => void
}) => {
  const { openNotice, handleCloseNotice, hideModal, handleClose } = props

  const handleConfirmRedirect = useCallback(() => {
    hideModal()
    handleRedirectIfDemonNotInstalled()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Drawer
      anchor="bottom"
      className="z-[1660] rounded-t-2xl"
      open={openNotice}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: (theme) => theme.palette.primary.main,
          borderRadius: '1rem 1rem 0 0',
        },
      }}
    >
      <WalletAccept
        handleCloseNotice={handleCloseNotice}
        handleConfirmNotice={handleConfirmRedirect}
      />
    </Drawer>
  )
}

const WalletItemsListBody = (props: {
  hideModal: () => void
  handleClose: (event: MouseEvent) => void
}) => {
  const { wallets, select, connect } = useWallet()
  const [openNotice, setOpenNotice] = React.useState(false)
  const { handleClose, hideModal } = props

  const demonWallet = useMemo(() => {
    return wallets.find(
      (wallet) => wallet.adapter.name === DemonWalletName || wallet.adapter.name === E2EWalletName,
    ) as Wallet
  }, [wallets])

  const handleClickOpenNotice = useCallback(() => {
    setOpenNotice(true)
  }, [])
  const handleCloseNotice = useCallback(() => {
    setOpenNotice(false)
  }, [])
  const handleConfirmNotice = useCallback(() => {
    openNewWindowWithCallback(getDemonWalletUrl(), () => {
      // eslint-disable-next-line no-console
      console.log('New window has been closed!')
      if (!isDemonWalletDetected()) {
        window.location.reload()
      }
    })
  }, [])

  const handleWalletClick = useCallback(
    (event: MouseEvent, walletName: WalletName) => {
      if (walletName === DemonWalletName) {
        if (!isDemonWalletDetected()) {
          handleClickOpenNotice()
          return
        }
      }
      select(walletName)
      handleClose(event)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClickOpenNotice, handleRedirectIfDemonNotInstalled, select, connect],
  )

  return (
    <>
      <WalletListItem
        key={demonWallet.adapter.name}
        handleClick={(event) => handleWalletClick(event, demonWallet.adapter.name)}
        wallet={demonWallet}
        isMobileDevice={isMobileDevice}
      />
      {!isMobileDevice ? (
        <DesktopExtensionInstallingConfirmationDialog
          openNotice={openNotice}
          handleCloseNotice={handleCloseNotice}
          handleConfirmNotice={handleConfirmNotice}
        />
      ) : (
        <MobileAppInstallingConfirmation
          openNotice={openNotice}
          handleCloseNotice={handleCloseNotice}
          hideModal={hideModal}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export const WalletModal: FC<WalletModalProps> = ({ className = '' }) => {
  const { ref, fadeIn, portal, handleClose, hideModal } = useHandleTabKey()
  const { trans } = useWalletModal()

  const renderContent = () => {
    // if (isMobileDevice && !isDemonWalletDetected()) {
    //   return <MobileAppInstallingConfirmation hideModal={hideModal} handleClose={handleClose} />
    // }

    return <WalletItemsListBody hideModal={hideModal} handleClose={handleClose} />
  }

  return (
    portal &&
    createPortal(
      <div
        aria-labelledby="wallet-adapter-modal-title"
        aria-modal="true"
        className={`wallet-adapter-modal ${fadeIn && 'wallet-adapter-modal-fade-in'} ${className}`}
        ref={ref}
        role="dialog"
      >
        <div className="wallet-adapter-modal-container">
          <div className="wallet-adapter-modal-wrapper">
            <div className="wallet-adapter-modal-title">
              {trans.connect_wallet}
              <button onClick={handleClose} className="wallet-adapter-modal-button-close">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 19L19 5M19.0001 19L5.00012 5"
                    stroke="#484848"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="wallet-adapter-modal-content">
              {renderContent()}

              <div className="first-time-connect-notice">
                <Image
                  className="inline"
                  src={WalletConnectImage}
                  height={62}
                  alt="wallet connect help icon"
                />

                <div className="first-time-connect-notice__text">
                  <Typography className="text-[#2E2E2E] font-semibold text-base sm:text-lg">
                    {trans.wallet_not_yet}
                  </Typography>
                  <Link
                    data-testid="wallet-learn-how-to-connect"
                    href="https://renec.org/support/cach-tao-vi-carax"
                    target="_blank"
                    rel="noreferrer"
                    className="text-base gap-2 text-[#033C59]"
                  >
                    {trans.learn_how_to_connect}
                    <Image
                      className="inline ml-2"
                      src={staticImportIcon(IconName.OPEN_LINK_ICON)}
                      alt={IconName.OPEN_LINK_ICON}
                      width={20}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wallet-adapter-modal-overlay" onMouseDown={handleClose} />
      </div>,
      portal,
    )
  )
}
