import { useEffect } from 'react'

import { useQueryClient } from 'react-query'
import { useRouter } from 'next/router'

const useRefetchLocaleContent = () => {
  const queryClient = useQueryClient()
  const { locale } = useRouter()

  useEffect(() =>
    queryClient.getQueryCache().findAll().map((query) => query.queryKey)
      .filter((x) =>
        [
          'fetchGetProperties',
          'fetchTransactionsByBuyerAddress',
          'fetchWidgetProperties',
        ].includes(String(x[0])),
      ).forEach(queryKeys => queryClient.refetchQueries(queryKeys))
  , [queryClient, locale])
}

export default useRefetchLocaleContent
