import { Dialog, Grid, Stack, Typography } from '@mui/material'
import PropEasyButton from '@/components/base/PropEasyButton'
import React from 'react'
import Image from 'next/image'
import { useForm, FormProvider } from 'react-hook-form'
import SingleChoice from '@/components/survey/question-type-componennts/single-choice'
import MultiChoice from '@/components/survey/question-type-componennts/multi-choice'
import InputAnswer from '@/components/survey/question-type-componennts/input-answer'
import RatingAnswer from '@/components/survey/question-type-componennts/rating-answer'
import TextareaAnswer from '@/components/survey/question-type-componennts/textarea-answer'
import Questionnaire from '@/assets/svgs/survey/questionnaire.svg'
import WalletIcon from '@/assets/svgs/survey/wallet.svg'
import TitleIcon from '@/assets/svgs/survey/title.svg'
import useTrans from '@/hooks/useTrans'

const QuestionnaireDialog = ({
  questionnaire,
  onSubmit,
  onClose,
}: {
  questionnaire: any
  onSubmit: (data: any) => void
  onClose: () => void
}) => {
  const trans = useTrans()
  const formMethods = useForm()
  const { handleSubmit, formState } = formMethods

  const questionRenderer = (x: any) => {
    switch (x.type) {
      case 'single_choice':
        return <SingleChoice data={x} />
      case 'multi_choice':
        return <MultiChoice data={x} />
      case 'input':
        return <InputAnswer data={x} />
      case 'textarea':
        return <TextareaAnswer data={x} />
      case 'rating':
        return <RatingAnswer data={x} />
      default:
        return null
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      classes={{
        paper: 'p-2 rounded-lg',
      }}
      open
      onClose={onClose}
    >
      <FormProvider {...formMethods}>
        <Stack className="p-3" gap={2} alignItems="center" justifyContent="center">
          <Image src={Questionnaire} alt="questionnaire" width={120} height={120} />
          <Typography className="text-center text-xl font-bold">
            {trans.survey.ask.title}
          </Typography>
          <Typography className="text-sm">{trans.survey.ask.desc}</Typography>
          <Grid
            container
            alignItems="center"
            className="p-4 my-2 rounded-lg border"
            style={{ background: 'linear-gradient(94deg, #E5E2E1 3.8%, #F2F2F2 99.06%)' }}
          >
            <Grid item xs="auto">
              <Image src={WalletIcon} alt="wallet" width={48} height={60} />
            </Grid>
            <Grid item xs={true}>
              <div
                className="text-sm ml-4"
                dangerouslySetInnerHTML={{
                  __html: trans.survey.ask.reward,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="mt-6 mb-3">
            <Grid item xs="auto">
              <Image src={TitleIcon} alt="title" width={64} height={64} />
            </Grid>
            <Grid item xs={true}>
              <Stack>
                <Typography className="text-2xl font-bold	text-[#052D41]">
                  {trans.survey.personalInfo.title}
                </Typography>
                <Typography className="text-[#052D41]">{trans.survey.personalInfo.desc}</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Stack className="w-full" gap={3}>
            {questionnaire.slice(0, 3).map((x: any) => questionRenderer(x))}
          </Stack>
          <Grid container spacing={2} className="mt-6 mb-3">
            <Grid item xs="auto">
              <Image src={TitleIcon} alt="title" width={64} height={64} />
            </Grid>
            <Grid item xs={true}>
              <Stack>
                <Typography className="text-2xl font-bold	text-[#052D41]">
                  {trans.survey.surveyInfo.title}
                </Typography>
                <Typography className="text-[#052D41]">{trans.survey.surveyInfo.desc}</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Stack className="w-full" gap={3}>
            {questionnaire.slice(3).map((x: any) => questionRenderer(x))}
          </Stack>
          <PropEasyButton
            rounded
            handleClick={handleSubmit(onSubmit)}
            text={trans.survey.submit}
            disabled={!formState.isValid}
          />
        </Stack>
      </FormProvider>
    </Dialog>
  )
}

export default QuestionnaireDialog
