import { useWallet } from '@solana/wallet-adapter-react'
import { useMemo } from 'react'
import { useFetchKycVerifications } from '@/hooks/use-kyc-verifications'
import { useFetchAssociatedTokenAddress } from '@/hooks/use-transactions'
import { WalletDetailModel } from '@/model/wallet-detail'

export const useWalletDetail = (): WalletDetailModel => {
  const { publicKey, wallet } = useWallet()

  const address = useMemo(() => publicKey?.toBase58() || '', [publicKey])
  const connected = useMemo(() => !(!wallet || !address), [wallet, address])
  const { data: dataKyc } = useFetchKycVerifications(address)
  const { data: balance, refetch: refetchBalance, isFetching: isBalanceLoading } = useFetchAssociatedTokenAddress(address)

  return {
    address,
    connected,
    kyc_completed: dataKyc?.kyc_completed || false,
    kyc_level: dataKyc?.level || 0,
    prop: {
      amount: balance?.prop.amount || '0',
      uiAmount: balance?.prop.uiAmount || 0,
      uiAmountString: balance?.prop.uiAmountString || '0',
    },
    reUsd: {
      amount: balance?.reUsd.amount || '0',
      uiAmount: balance?.reUsd.uiAmount || 0,
      uiAmountString: balance?.reUsd.uiAmountString || '0',
    },
    renecBalance: balance?.renecBalance || 0,
    referral: balance?.referral,
    refetchBalance,
    isBalanceLoading,
  }
}
